<template>
  <v-card class="access-tile ma-2">
    <v-card-title>
      <strong>{{ title }}</strong>
    </v-card-title>
    <v-alert :value="error" type="error" dismissible>
      {{ error }}
    </v-alert>
    <v-card-text class="py-4">
      <v-layout column>
        <v-flex>
          <v-text-field
            outline
            v-model="inputValue"
            :label="inputLabel"
            autocomplete="false"
            required
            @keyup.enter="goSearch()"
            :disabled="loading"
            @input="$emit('input', inputValue)"
          ></v-text-field>
          <v-layout justify-end>
            <v-flex xs4 sm2 class="d-flex">
              <v-btn v-if="!isConfigureSubscription" color="green" round outline small @click.stop="goSearch()" :loading="loading">
                Search
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex v-if="searched_items && searched_items.length > 0">
          <v-divider class="my-4"></v-divider>
          <v-layout column>
            <v-flex xs12 class="text-xs-left mx-4">
              <strong>{{ searchedListTitle }}</strong>
            </v-flex>
            <v-flex xs12 class="mt-4 searched-users__list">
              <v-list class="pa-0">
                <v-list-tile v-for="(user, i) in searched_items" :key="i" class="ma-0 users-list  px-2" @click="goSearch(user.search)">
                  <v-list-tile-content>
                    <v-list-tile-title>
                      <v-layout row>
                        <v-flex sm6 xs8>{{ user.datetime }}</v-flex>
                        <v-flex sm6 xs4 class="text-ellipsis">{{ user.search }}</v-flex>
                      </v-layout>
                    </v-list-tile-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-layout row>
                      <v-flex xs12 text-xs-right mr-1>
                        <v-btn icon ripple @click.stop="$store.commit('Admin/removeSearchedUser', user.search)" class="ml-1">
                          <v-icon small color="red">close</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-list-tile-action>
                </v-list-tile>
              </v-list>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-divider class="ma-auto"></v-divider>
    <v-card-actions v-if="isConfigureSubscription" class="pa-3 flex flex-wrap">
      <v-btn @click="$emit('back')" color="gray" :disabled="loading" flat="flat">
        BACK
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="green" flat="flat" :disabled="loading || !inputValue" @click="$emit('next')">
        NEXT
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    error: {
      default: null,
    },
    loading: {
      default: null,
    },
    value: {
      required: true,
    },
    title: {
      default: "",
    },
    inputLabel: {
      default: "",
    },
    searchedListTitle: {
      type: String,
      default: "Searched users:",
    },
    forceLowerCase: {
      type: Boolean,
      default: true,
    },
    isConfigureSubscription: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  computed: {
    ...mapState({
      searched_items: (state) => state.Admin.searchedUsers,
    }),
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.$store.dispatch("Admin/getSearchedUsers");
      });
    },
    goSearch(search = null) {
      this.inputValue = search || this.inputValue || "";
      if (this.forceLowerCase) this.inputValue = this.inputValue.toLowerCase();

      this.$emit("input", this.inputValue);
      this.$emit("search");
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="scss">
.searched-users {
  &__list {
    max-height: 500px;
    overflow-y: auto;
  }
}
.overflow-x-auto {
  overflow-x: auto;
}
.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
