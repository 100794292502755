<template>
  <v-layout xs12 row justify-center align-center class="h-full">
    <v-flex md6 sm12 xs12>
      <SearchCard :error="error" :loading="loading" v-model="search" @search="goSearch" @input="(v) => (search = v)" title="Lookup Users" inputLabel="Enter User E-mail" searched-list-title="Searched Users:" />
    </v-flex>
  </v-layout>
</template>
<script>
import Api from "../../library/apis/Api";
import { mapState } from "vuex";
import SearchCard from "./components/SearchCard";
export default {
  components: {
    SearchCard,
  },
  data() {
    return {
      loading: false,
      error: null,
      search: null,
    };
  },
  computed: {
    ...mapState({
      userview: (state) => state.Admin.userview,
    }),
  },
  methods: {
    goSearch() {
      this.loading = true;
      this.error = null;
      this.search = this.search.trim();
      this.$store.dispatch("resetState");
      this.$store.dispatch("Admin/resetState");
      Api.adminUser({ username: this.search })
        .then((users) => {
          if (users.length > 0) {
            this.$store.commit("Admin/setSearchedUsers", this.search);
            this.$store.commit("Admin/setState", { userview: users[0] });
            // this.$store.dispatch("countData");
            this.$router.push("/admin/userview-dashboard");
            this.$nextTick(() => {
              this.$store.dispatch("Admin/getPreferencesForView");
            })
          } else this.error = "User Does Not Exist";
        })
        .catch((error) => {
          this.error = "User Does Not Exist";
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style lang="scss">
.h-full {
  height: 100%;
}
</style>
