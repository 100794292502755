<template>
  <v-container fluid :class="{ 'fill-height text-xs-center full-inner-height': !visibleWalletData }">
    <v-layout row wrap v-if="!!visibleWalletData">
      <v-flex xs3 sm5 class="text-xs-left page-toolbar">
        <h1 class="d-inline-block mt-2" style="font-size: 25px">{{ title }}</h1>
      </v-flex>
      <v-flex xs9 sm7 class="text-xs-right page-toolbar">
        <CreateWallet class="ml-3 d-inline-block" :currencies="walletCurrencies" @close="getWallets" />
        <div class="ml-2 d-inline-block">
          <v-btn
            small
            dark
            color="green"
            class="plugzio-button create-button mx-0 px-3 mb-1 mt-1"
            style="min-width: auto; height: 30%; border-radius: 30px"
            round
            outline
            @click="showHiddenWallet = !showHiddenWallet"
          >
            <v-icon size="20" class="ma-0" key="icon">wallet</v-icon>
            <span class="ml-1 hidden-md-and-down" key="description"> {{ showHiddenWallet ? "HIDE" : "SHOW" }} HIDDEN WALLETS </span>
          </v-btn>
        </div>
        <ExportWalletData :filter="data.filter" :is-owner="role == 'owner' || (role == 'admin' && adminActiveView == 'managerview')" class="mt-1 ml-3 d-inline-block" />
        <DateRange ref="dateRange" class="ml-1 date-range" style="margin: 5px" :with-timezone="role == 'admin'" timezone-text-position="left" />
      </v-flex>
      <template v-for="(wallet, index) in wallets">
        <v-flex v-if="showHiddenWallet || !hiddenWallets.includes(wallet.id)" lg4 md6 xs12 :key="index">
          <v-layout column>
            <v-card class="ma-2">
              <v-card-title class="flex">
                <h3 class="font-weight-medium">{{ wallet.description }}</h3>
                <v-spacer></v-spacer>
                <span class="mr-2">
                  <v-progress-circular v-if="walletsLoading" indeterminate :color="role == 'owner' ? 'green' : 'orange'" :size="24"></v-progress-circular>
                  <v-icon v-else class="pointer" @click="getWallets()">refresh</v-icon>
                </span>
              </v-card-title>
              <div class="flex pa-3">
                <div
                  class="wallet py-4 px-2"
                  :class="{
                    'owner-wallet': role == 'owner' || (role == 'admin' && !!managerview),
                    'wallet-hidden': hiddenWallets.includes(wallet.id),
                  }"
                  style="border-radius: 10px 10px 0 0"
                >
                  <div class="amount text-xs-center">
                    <v-layout align-center justify-center>
                      <span class="mr-1" style="color: rgba(255, 255, 255, 0.6)">Available balance</span>
                      <v-icon
                        class="tooltip__color__inherit"
                        v-tooltip="{
                          content: 'Balance shown does not take into account pending transactions & active sessions',
                          trigger: 'click hover',
                        }"
                        style=""
                      >
                        info
                      </v-icon>
                    </v-layout>
                    <h2>
                      {{ floorNumberToTwoDecimal(wallet.balance) | numeral("0,0.00") }}
                      {{ wallet.currency && wallet.currency != "null" ? wallet.currency : "" }}
                    </h2>
                  </div>
                </div>
                <v-card-actions
                  class="wallet"
                  :class="{
                    'owner-wallet': role == 'owner' || (role == 'admin' && !!managerview),
                    'wallet-hidden': hiddenWallets.includes(wallet.id),
                    'wallet-action__disabled': !allowedFeatures.WITHDRAWAL && !allowedFeatures.TOPUP && !allowedFeatures.EDIT && !allowedFeatures.REFUND,
                  }"
                  style="border-radius: 0 0 10px 10px"
                >
                  <v-layout xs12>
                    <v-btn
                      v-if="role !== 'user' && !userview"
                      @click="showWithdrawalDialog(wallet)"
                      class="flex ml-3 xs4 wallet-card__action"
                      flat
                      :disabled="!allowedFeatures.WITHDRAWAL"
                    >
                      <v-icon class="mr-2"> payments </v-icon>
                      WITHDRAW
                    </v-btn>

                    <v-btn
                      v-if="role === 'admin'"
                      @click="showRefundDialog(wallet)"
                      class="d-flex ml-4 flex xs6 ma-auto wallet-card__action"
                      :disabled="wallet.balance <= 0 || !allowedFeatures.REFUND"
                      flat
                    >
                      <v-icon class="mr-2"> currency_exchange </v-icon>
                      REFUND
                    </v-btn>

                    <TopUpModal
                      v-if="role != 'admin'"
                      :wallet="wallet"
                      @success="getWallets"
                      class="d-flex ml-3 ma-auto flex"
                      :class="[role == 'owner' ? 'xs4' : 'xs6']"
                      :disabled="!allowedFeatures.TOPUP"
                    >
                      <v-btn class="flex wallet-card__action ma-auto" flat :disabled="!allowedFeatures.TOPUP">
                        <v-icon class="mr-2"> add </v-icon>
                        TOP UP
                      </v-btn>
                    </TopUpModal>

                    <v-btn
                      class="flex wallet-card__action ma-auto"
                      :class="[role === 'owner' && managerview ? 'xs4' : 'xs6']"
                      flat
                      @click="$store.dispatch('editWallet', wallet)"
                      :disabled="!allowedFeatures.EDIT"
                    >
                      <v-icon class="mr-2"> edit </v-icon>
                      Edit
                    </v-btn>
                  </v-layout>
                </v-card-actions>
              </div>
              <v-card-text>
                <Transactions
                  :wallet-id="wallet.id"
                  :currency="wallet.currency"
                  @session="showSessionLog"
                  @copy="(id) => selectWallet(id, wallet)"
                  @loadMoreTransactionClicked="$refs.dateRange.showDialog()"
                />
              </v-card-text>
            </v-card>
          </v-layout>
        </v-flex>
      </template>
    </v-layout>
    <v-layout align-center justify-center>
      <v-progress-circular class="loading-circle mb-3" size="50" indeterminate color="orange" v-if="!!walletsLoading" />
      <Empty :data="visibleWalletData" :width="465" v-if="!visibleWalletData && !walletsLoading">
        <ZeroWallets :is-owner="role == 'owner' || adminActiveView == 'managerview'" :visible-wallets="!visibleWalletData && rawWallets.length < 1" />
        <CreateWallet v-if="!allCurrenciesIncluded" :currencies="walletCurrencies" always-show-button-text @close="getWallets" />
        <v-btn
          v-if="role !== 'owner' && adminActiveView !== 'managerview' && allCurrenciesIncluded"
          small
          dark
          color="green"
          class="plugzio-button create-button mx-0 px-3 mb-1 mt-1"
          style="min-width: auto; height: 30%; border-radius: 30px"
          round
          outline
          @click="showHiddenWallet = !showHiddenWallet"
        >
          <v-icon size="20" class="ma-0" key="icon">wallet</v-icon>
          <span class="ml-1 hidden-md-and-down" key="description"> SHOW ALL HIDDEN WALLETS </span>
        </v-btn>
      </Empty>
    </v-layout>
    <SessionLog :selected="selectedSession">
      <v-btn
        v-if="role !== 'user' && !this.userview"
        small
        dark
        color="green"
        class="plugzio-button create-button mx-2 px-3 my-1"
        style="min-width: auto; height: 30%; border-radius: 30px"
        round
        outline
        :loading="loading"
        @click="gotoOutlet(selectedSession.plug_id)"
      >
        <v-icon size="18" class="ma-0">settings</v-icon>
        <span class="ml-1 hidden-md-and-down" key="description"> MANAGE DEVICE </span>
      </v-btn>
    </SessionLog>
    <Withdrawal :wallet="selectedWallet" v-model="isShowWithdrawalDialog" @success="getWallets" />
    <Refund :wallet="selectedWallet" v-model="isShowRefundDialog" @success="getWallets" />
    <CopyToClipboard :show-dialog="clipBoardDialog" @open="clipBoardDialog = true" :selected="selectedTransactionId" :wallet="selectedWallet" @close="closeTransaction" />
  </v-container>
</template>

<script>
import mixin from "@/mixins";
import sessionsFormat from "@/mixins/sessionsFormat";
import Api from "@/library/apis/Api";
import { mapState, mapGetters } from "vuex";
import { floorNumberToTwoDecimal, OwnerProxyHelpers } from "@/library/helpers";
import ZeroWallets from "@/components/zero/Wallets.vue";
import SessionLog from "@/components/modals/SessionLog.vue";
import ExportWalletData from "@/components/modals/ExportWalletData.vue";
import Withdrawal from "@/components/modals/Withdrawal.vue";
import TopUpModal from "@/components/modals/TopUp/TopUpModal.vue";
import DateRange from "@/components/modals/DateRange.vue";
import Transactions from "@/components/Transactions.vue";
import CreateWallet from "@/components/modals/CreateWallet.vue";
import Empty from "@/containers/Empty.vue";
import Refund from "@/components/modals/Refund.vue";
import CopyToClipboard from "@/components/modals/CopyToClipboard.vue";
import supportedCurrencies from "@/data/supportedCurrencies";

export default {
  mixins: [mixin, sessionsFormat],
  components: {
    CopyToClipboard,
    DateRange,
    Transactions,
    CreateWallet,
    Empty,
    ZeroWallets,
    SessionLog,
    ExportWalletData,
    Withdrawal,
    TopUpModal,
    Refund,
  },
  data() {
    return {
      showHiddenWallet: false,
      rawWallets: [],
      walletIds: [],
      loading: false,
      walletsLoading: false,
      actionSub: null,
      selectedSession: null,
      clipBoardDialog: false,
      selectedTransactionId: null,
      selectedWallet: null,
      sessionsData: {
        since: null,
        till: null,
        data: [],
      },
      valid: true,
      data: {
        filter: null,
      },
      isShowWithdrawalDialog: false,
      isShowRefundDialog: false,
    };
  },
  computed: {
    ...mapState({
      adminActiveView: (state) => state.Admin.activeView,
      managerview: (state) => state.Admin.managerview,
      role: (state) => state.Auth.role,
      userview: (state) => state.Admin.userview,
    }),
    ...mapGetters("Admin", {
      adminViewAsUser: "viewAsUser",
    }),
    ...mapGetters("Wallet", {
      hiddenWallets: "hiddenWallets",
    }),
    ...mapGetters({
      plugs: "plugs",
    }),
    filteredRawWallets() {
      return this.rawWallets.filter((wallet) => !this.hiddenWallets.includes(wallet.id));
    },
    visibleWalletData() {
      return !this.showHiddenWallet ||
        (this.allCurrenciesIncluded && this.adminActiveView === "userview" && this.role === "user") ||
        (this.role === "owner" && this.adminActiveView === "managerview")
        ? this.filteredRawWallets.length > 0
        : this.rawWallets.length > 0;
    },
    supportedCurrencies: () => supportedCurrencies.map((o) => o.country + " (" + o.currency_code + ")").sort(),
    allowedFeatures() {
      return {
        WITHDRAWAL: OwnerProxyHelpers.isHaveAccessToFeature("WALLET_WITHDRAWAL"),
        TOPUP: OwnerProxyHelpers.isHaveAccessToFeature("WALLET_TOPUP"),
        CREATE: OwnerProxyHelpers.isHaveAccessToFeature("WALLET_CREATE"),
        EDIT: OwnerProxyHelpers.isHaveAccessToFeature("WALLET_EDIT"),
        REFUND: OwnerProxyHelpers.isHaveAccessToFeature("WALLET_REFUND"),
      };
    },
    storedTime() {
      return this.$store.getters.storedTime;
    },
    wallets() {
      let wallets = this.rawWallets;
      return wallets.sort((a, b) => a["id"] - b["id"]);
    },
    walletCurrencies() {
      let currencies = [];
      this.rawWallets.forEach((o) => {
        currencies.push(o.currency);
      });
      return currencies;
    },
    allCurrenciesIncluded() {
      return this.rawWallets.length === this.supportedCurrencies.length;
    },
    title() {
      if (!this.adminActiveView) return "Wallets";
      if (this.adminActiveView == "userview") {
        return !!this.adminViewAsUser ? `${this.adminViewAsUser.username} > Wallets` : "Wallets";
      }
      if (this.adminActiveView == "managerview") {
        return !!this.managerview ? `${this.managerview.owner_username} > Wallets` : "Wallets";
      }
      return "Wallets";
    },
    query() {
      return this.$route.query;
    },
  },
  watch: {
    isShowWithdrawalDialog: function (value) {
      if (!value) this.selectedWallet = null;
    },
    isShowRefundDialog: function (value) {
      if (!value) this.selectedWallet = null;
    },
  },
  methods: {
    selectWallet(transactionId, wallet) {
      this.selectedTransactionId = transactionId;
      this.selectedWallet = wallet;
    },
    closeTransaction() {
      this.clipBoardDialog = false;
      this.selectedWallet = null;
      this.selectedTransactionId = null;
    },
    getWallets() {
      this.walletsLoading = true;
      this.walletIds = [];
      const apiPrefix = this.adminActiveView == "managerview" ? "admin/owner" : this.adminActiveView == "userview" ? "admin/user" : this.role;
      let formData = {};
      if (this.role === "admin") {
        if (!!this.managerview) formData = { ownerUsername: this.managerview.owner_username };
        if (!!this.userview) formData = { username: this.adminViewAsUser.username };
      }

      Api.wallets(apiPrefix, formData)
        .then((data) => {
          this.rawWallets = data || [];

          this.$store.dispatch("loadTransactions");
          this.setWalletTransactions();
          if (this.storedTime.since !== this.sessionsData.since && this.storedTime.till !== this.sessionsData.till) {
            this.getSessions();
          }
          this.walletsLoading = false;
        })
        .catch((error) => {
          this.walletsLoading = false;
        });
    },
    getSessions() {
      const formData = { since: this.storedTime.since, till: this.storedTime.till };
      if (!!this.managerview) formData.ownerUsername = this.managerview.owner_username;
      if (!!this.adminViewAsUser) formData.username = this.adminViewAsUser.username;
      let apiPrefix = this.role != "admin" ? this.role : !!this.managerview ? "admin/owner" : "admin/user";
      Api.sessions(apiPrefix, formData)
        .then((data) => {
          if (data) {
            this.sessionsData = {
              since: this.storedTime.since,
              till: this.storedTime.till,
              data: this.formatSessions(data, this.plugs),
            };
            this.sessionsData.data.map((o) => {
              o.wallet = this.wallets.find((wallet) => {
                return wallet.id === o.owner_wallet_id || o.user_wallet_id;
              });
              return o;
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    showSessionLog(evt) {
      if (evt) {
        this.selectedSession = null;
        this.$nextTick(() => {
          this.selectedSession = this.sessionsData.data.find((o) => {
            return o.id === evt;
          });
        });
      }
    },
    // gotoSessions() {
    //   this.$nextTick(() => {
    //     this.$router.push({ name: `${this.roleSetting.routePrefix}sessions` });
    //   });
    // },
    gotoOutlet(plugIdentifier) {
      this.$store.dispatch("outletHighlight", plugIdentifier);
      const routeName = this.role === "admin" ? "managerview-outlet-management" : "outlet-management";
      this.$nextTick(() => {
        this.$router.push({
          name: `${this.roleSetting.routePrefix}${routeName}`,
        });
      });
    },
    setWalletTransactions() {
      const walletTransactions = [];
      for (let index = 0; index < this.rawWallets.length; index++) {
        const wallet = this.rawWallets[index];
        wallet["transactions"] = [];
        walletTransactions.push(wallet);
      }
      this.$store.dispatch("setWalletTransactions", walletTransactions);
    },
    showWithdrawalDialog(wallet) {
      this.isShowWithdrawalDialog = true;
      this.selectedWallet = wallet;
    },
    showRefundDialog(wallet) {
      this.isShowRefundDialog = true;
      this.selectedWallet = wallet;
    },
    floorNumberToTwoDecimal(number) {
      return floorNumberToTwoDecimal(number);
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.role == "admin" && !this.managerview && !this.adminViewAsUser) return this.$router.push(`/admin/${this.adminActiveView}`);
      this.getWallets();
      this.actionSub = this.$store.subscribeAction((action) => {
        if (action.type === "loadWallets") this.getWallets();
      });
    });
  },
  beforeDestroy() {
    if (!!this.actionSub) this.actionSub();
  },
};
</script>

<style lang="scss" scoped>
@import "./../styles/colors.scss";

.wallet {
  color: #ffffff;
  background-color: $owner-color !important;
  &-hidden {
    background-color: grey !important;
  }
  &-card {
    &__action {
      color: rgba(255, 255, 255, 0.8);
    }
  }
  &-action__disabled {
    background-color: rgba(0, 0, 0, 0.26) !important;
  }
}

.owner-wallet {
  background-color: $user-color !important;
  &.wallet-hidden {
    background-color: #7f7f7f !important;
  }
  &.wallet-action__disabled {
    background-color: rgba(0, 0, 0, 0.26) !important;
  }
}
.amount {
  h2 {
    font-weight: 300;
    font-size: 3em;
  }
}

.top-up-note {
  font-size: 0.85em;
  color: gray;
}
</style>
<style lang="scss">
.tooltip {
  &__color {
    &__inherit {
      color: inherit !important;
    }
  }
}
.wallet-card {
  &__header {
    &__title {
      padding: 10px;
    }
  }
}
</style>
