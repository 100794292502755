<template>
  <v-card class="access-tile ma-2">
    <v-card-title>
      <strong>Lookup Device Logs</strong>
    </v-card-title>
    <v-alert :value="error" type="error" dismissible>
      {{ error }}
    </v-alert>
    <v-card-text>
      <v-layout column>
        <v-flex>
          <v-layout row wrap items-center class="d-flex mb-4">
            <v-flex xs12>
              <span v-if="!responsive" class="mr-2">Date & time: </span>
              <DateRange
                ref="dateRange"
                class="date-range"
                :default-date-range="dateRange"
                :with-time="true"
                :hide-on-mobile="false"
                :showPresets="['today', 'yesterday', 'last_12_hours', 'last_24_hours', 'last_7_days', 'last_30_days', 'last_365_days']"
              />
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12 md2>
              <v-text-field
                outline
                v-model="filters.subdomain"
                label="Subdomain"
                autocomplete="false"
                required
                @keyup.enter="getData()"
                :disabled="loading"
              />
            </v-flex>
            <v-flex xs12 md2>
              <v-text-field
                :class="{ 'pl-4': !responsive }"
                outline
                v-model="filters.level"
                label="Level"
                autocomplete="false"
                required
                @keyup.enter="getData()"
                :disabled="loading"
              />
            </v-flex>
            <v-flex xs12 md2>
              <v-card-actions class="pb-4 px-4">
                <v-btn color="green" round outline @click.stop="getData()" :loading="loading">
                  Search
                </v-btn>
              </v-card-actions>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card-text>

    <v-card-text style="border-top:1px solid #eaeaea" v-if="allowFetchData">
      <v-layout column>
        <v-layout justify-space-between align-center xs12>
          <RegexTextfield
            v-model="search.text"
            :enable-regex="search.enableRegex"
            @regexToggled="(v) => (search.enableRegex = v)"
            :disabled="loading"
            prepend-icon="search"
          />

          <v-flex >
            <v-card-actions class="justify-end">
              <v-btn
                v-if="logs.length > 0 && !allDataFetched"
                class="ml-4"
                color="green"
                round
                outline
                small
                @click.stop="getData({ reset: false, since: lastLogTimestamp })"
                :loading="loading"
              >
                Fetch More Data
              </v-btn>
            </v-card-actions>
          </v-flex>
          <DataExporter
            title="Export Device Logs"
            button-class="my-0"
            :data="exportableCSV"
            :filename="`Device Logs Data (${$moment.unix(storedTime.since).format('YYYY-MM-DD')} - ${$moment.unix(storedTime.till).format('YYYY-MM-DD')})_${$moment().format('YYYYMMDDHHmmss')}.csv`"
          >
            Date From:
            <strong>{{ $moment.unix(storedTime.since).format("YYYY-MM-DD") }}</strong>
            <br />Date To:
            <strong>{{ $moment.unix(storedTime.till).format("YYYY-MM-DD") }}</strong>
            <div class="modal-description mt-2">
              Note: You can change the calendar date and search results to filter the data you want exported.
            </div>
          </DataExporter>
        </v-layout>
        <v-flex class="mt-2">
          <vue-good-table
            v-show="show.result"
            :columns="headers"
            :rows="items"
            :sort-options="{
              enabled: true,
              initialSortBy: { field: 'formatted_timestamp', type: 'desc' },
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 100,
              position: 'top',
              perPageDropdown: [250, 500, 1000],
              dropdownAllowAll: false,
              setCurrentPage: 1,
              jumpFirstOrLast : true,
              firstLabel : 'First Page',
              lastLabel : 'Last Page',
              nextLabel: 'next',
              prevLabel: 'prev',
              rowsPerPageLabel: 'Rows per page',
              ofLabel: 'of',
              pageLabel: 'page', // for 'pages' mode
              allLabel: 'All',
              infoFn: (params) => `${params.firstRecordOnPage} - ${params.lastRecordOnPage} of ${params.totalRecords} ${allDataFetched ? '(all data are fetched)' : ''}`
            }">
          >
            <div slot="emptystate" v-if="!loading">
              &nbsp; No result
            </div>
            <div slot="emptystate" v-else>
              &nbsp; Fetching results...
            </div>
          </vue-good-table>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import LogHelpers from "@/library/helpers/LogHelpers";
import { last } from 'lodash-es';
import DataExporter from "@/components/modals/DataExporter.vue";
import DateRange from "@/components/modals/DateRange";
import RegexTextfield from "@/components/inputs/RegexTextfield";

export default {
  name: "DeviceLogsCard",
  components: {
    DateRange,
    RegexTextfield,
    DataExporter,
  },
  props: {
    device: {
      default: {}
    }
  },
  data() {
    return {
      error: null,
      filters: {
        subdomain: null,
        level: null,
      },
      search: {
        text: null,
        enableRegex: false,
      },
      show: {
        result: true,
      },
      headers: [
        {
          label: "ID",
          field: "id",
          sortable: true,
          tdClass: "white-space__nowrap",
          type: "number",
        },
        {
          label: "TimeStamp",
          field: "formatted_timestamp",
          sortable: true,
          tdClass: "white-space__nowrap",
        },
        {
          label: "Level",
          field: "level",
          sortable: true,
          tdClass: "white-space__nowrap",
        },
        {
          label: "Domain",
          field: "domain",
          sortable: true,
          tdClass: "white-space__nowrap",
        },
        {
          label: "Sub Domain",
          field: "subdomain",
          sortable: true,
          tdClass: "white-space__nowrap",
        },
        {
          label: "Message",
          field: "message",
          sortable: true,
        },
      ],
      allowFetchData: false,
      allDataFetched: false
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      responsive: (state) => state.responsive,
      time: (state) => state.time,
      timezone: (state) => state.Global.timezone,
      adminPersistentState: (state) => state.Admin.persistents,
    }),
    ...mapGetters("Global", {
      timezone: "getTimezoneText",
    }),
    ...mapGetters("Log", {
      logs: "data",
    }),
    items() {
      let items = this.logs;
      const filters = {
        search: this.search.text,
        enableRegexSearch: this.search.enableRegex,
        sort_by: "id",
        sort_direction_desc: false,
      };
      return LogHelpers.filter(items, filters);
    },
    storedTime() {
      return {
        since: this.$moment(this.time.since * 1000).unix(),
        till: this.$moment(this.time.till * 1000).unix(),
        timezone: this.timezone,
      };
    },
    exportableCSV() {
      let data = ``;
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        data += `"` + item.id.toString() + `",`;
        data +=
          `"` +
          this.$moment
            .unix(item.timestamp)
            .format("YYYY-MM-DD HH:mm:ss")
            .toString() +
          `",`;
        data += `"` + item.level.toString() + `",`;
        data += `"` + this.device.internal_identifier + `",`;
        data += `"` + item.subdomain + `",`;
        data += `"` + item.message.replaceAll('"', '""') + `"\n`;
      }
      return (
        `From Date:,${this.$moment.unix(this.storedTime.since).format("YYYY-MM-DD HH:mm:ss")},,,,,\n` +
        `To Date:,${this.$moment.unix(this.storedTime.till).format("YYYY-MM-DD HH:mm:ss")},,,,,\n` +
        `Time Zone (GMT):,"${this.timezone}",,,,,\n\n` +
        `Domain:,"${this.device.internal_identifier ? this.device.internal_identifier : ""}",,,,,\n` +
        `Subdomain:,"${this.filters.subdomain ? this.filters.subdomain : ""}",,,,,\n` +
        `Level:,"${this.filters.level ? this.filters.level : ""}",,,,,\n` +
        `Search Filter:,"${this.search.text ? this.search.text : ""}",,,,,\n` +
        `Regex:,"${this.search.enableRegex ? "On" : "Off"}",,,,,\n\n` +
        `ID,Timestamp (YYYY-MM-DD HH:MM:SS),Level,Domain,Sub Domain, Message\n` +
        `${data},,,,,,`
      );
    },
    dateRange() { 
      return {
        since: this.adminPersistentState.starttime || this.$moment().subtract(30, "day").startOf("day").unix(),
        till: this.adminPersistentState.endtime || this.$moment().endOf("day").unix(),
      }
    },
    lastLogTimestamp() {
      return this.logs.length > 0 ? last(this.logs).timestamp : this.storedTime.since
    }
  },
  watch: {
    storedTime: {
      handler(time) {
        this.$store.commit("Admin/setState", { persistents: { ...this.adminPersistentState, starttime: time.since, endtime: time.till, timezone: time.timezone }})

        if (!this.loading && this.allowFetchData)
          this.getData();
      },
      deep: true,
    },
  },
  methods: {
    async getData({ reset = true, since = this.storedTime.since } = {}) {
      if (reset) {
        this.allDataFetched = false
        this.$store.dispatch("Log/set", [])
      }
      this.allowFetchData = true;
      this.$store.dispatch("loading", true);
      var formData = new FormData();
      formData.append("since", since);
      formData.append("till", this.storedTime.till);
      for (const key in this.filters) {
        if (!!this.filters[key]) formData.append(key, this.filters[key]);
      }
      const { internal_identifier } = this.device
      if (!!internal_identifier) {
        formData.append("domain", internal_identifier)
      }
      this.$store.dispatch("Log/getData", { formData, reset })
        .then((data) => {
          if ((data || []).length < 1000) {
            this.allDataFetched = true
          }
        })
        .catch()
        .finally(() => this.$store.dispatch("loading", false));
    },
  },
};
</script>
<style lang="scss">
.vgt-table {
  overflow-wrap: anywhere;
  thead {
    overflow-wrap: normal;
  }
}
</style>
