<template>
  <v-container fluid class="fill-height text-xs-center full-inner-height">
    <v-layout align-center justify-center>
      <v-flex xs12 lg6>
        <v-card>
          <v-card-title class="flex">
            <h3>Verify Mobikwik</h3>
          </v-card-title>
          <v-card-text>
            <v-flex
              xs12
              v-if="!!loading.show"
            >
              <v-progress-circular class="loading-circle center-margin" size="50" indeterminate color="orange" />
            </v-flex>
            <div
              v-else-if="error"
              class="mb-4"
            >
              <v-alert 
                :value="error"
                type="error"
              >
                {{ error }}
              </v-alert>
            </div>
          </v-card-text>

          <v-card-actions v-if="error" class="pa-3 flex">
            <v-btn color="gray" flat="flat" @click="cancel">
              Cancel
            </v-btn>
            <v-spacer />
            <v-btn color="green" flat="flat" @click="verify()">Retry</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Api from '@/library/apis/Api';
import { MobikwikHelpers } from '@/library/helpers';
import { mapGetters } from 'vuex';
export default {
  name: "VerifyMobikwik",
  data() {
    return {
      error: null,
      loading: {
        text: "",
        show: false
      }
    };
  },
  computed: {
    ...mapGetters("Auth", {
      roleSetting: "getRoleSetting",
    }),
    props() {
      return this.$route.query;
    }
  },
  methods: {
    cancel() {
      this.$router.replace({ name: `${this.roleSetting.routePrefix}wallets` })
    },
    async verify() {
      try {
        this.error = null
        this.loading.show = true;
        this.loading.text = "Checking status...";
        const statusResponse = await Api.mobikwikCheckStatus(this.props.orderId)

        if (!statusResponse || statusResponse.statusmessage !== "success") {
          throw "Unable to verify Mobikwik transaction . Please contact customer support."
        }

        let newToken = null
        try {
          const { token } = await Api.mobikwikTokenRegenerate(this.props.phone, this.props.token)
          if (!token) throw "Failed to regenerate token"
          newToken = token
        } catch (e) {}
        MobikwikHelpers.updateSavedTokens(this.props.phone, newToken)

        this.loading.text = "Topup plugzio..."
        const success = await Api.walletTopupMobikwik(JSON.stringify({ orderId: this.props.orderId }))
        if (!success) throw "Unable to verify Mobikwik transaction . Please contact customer support."
        this.cancel()
      } catch (error) {
        const defaultError = "Unable to verify Mobikwik transaction . Please contact customer support."
        this.error = error === defaultError ? error : `${defaultError} ${error}${error.response && error.response.data ? `, "${error.response.data}".` : "."}`
        this.loading.show = false;
      }
    }
  },
  mounted() {
    this.verify()
  }
}
</script>