<template>
  <v-container fluid class="fill-height text-xs-center full-inner-height">
    <v-layout align-center justify-center>
      <v-flex xs12 :md6="[null, 'insert-device-ids', 'set-state', 'insert-manager-name'].includes(menu)" :md12="['device-table', 'table-summary'].includes(menu)">
        <SearchCard
          v-if="menu == 'insert-manager-name'"
          :error="error"
          :loading="loading"
          v-model="search"
          @search="goSearch"
          @input="(v) => (search = v)"
          title="Configure Device Subscription Time"
          inputLabel="Enter Manager E-mail"
          searched-list-title="Searched Managers:"
          is-configure-subscription
          @back="goBack()"
          @next="goNext()"
        />
        <v-card v-else>
          <v-card-title>
            <h3>Configure Device Subscriptions Time</h3>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-alert :value="error" type="error" dismissible>
            {{ error }}
          </v-alert>
          <v-card-text>
            <v-flex xs12 v-if="!!loading">
              <v-progress-circular class="loading-circle center-margin" size="50" indeterminate color="orange" />
            </v-flex>
            <v-layout v-if="menu === null" xs12 class="py-4 my-4" justify-content-start align-center flex column>
              <v-btn
                v-model="id"
                id="device-ids"
                small
                class="plugzio-button create-button py-2 px-2 my-2 mx-2"
                style="width: 220px; height: 50%; border-radius: 10px;"
                round
                outline
                @click="goNext('device-ids')"
              >
                <strong>ENTER DEVICE IDs</strong>
              </v-btn>
              <v-btn
                v-model="lookup"
                id="lookup-manager"
                small
                class="plugzio-button create-button py-2 px-2 my-2 mx-2"
                style="width: 220px; height: 70%; border-radius: 10px;"
                round
                outline
                @click="goNext('lookup-manager')"
              >
                <strong>Lookup Devices By Manager</strong>
              </v-btn>
            </v-layout>
            <DeviceTable
              v-if="menu == 'device-table'"
              hide-search-bar
              hide-filter
              hide-select-all
              allow-selection
              hide-auto-payment
              :selected="selectedDevices"
              @selected="selectedDevices = $event"
            />
            <v-form ref="form" v-else-if="!loading">
              <v-layout xs12 justify-content-start flex column>
                <template v-if="menu == 'insert-device-ids'">
                  <strong class="d-flex">Enter Device IDs:</strong>
                  <v-textarea
                    id="inputs-device-ids"
                    class="mt-1"
                    v-model="inputs.ids"
                    outline
                    :rules="[validators.required, (v) => validators.maxCharacter(v, 1000)]"
                    counter="1000"
                    rows="10"
                    auto-grow
                    label="Device IDs"
                  />
                </template>
                <template v-if="menu == 'set-state'">
                  <v-layout xs12 d-flex row>
                    <v-flex xs3 align-center d-flex>
                      <strong class="mr-2">Set State:</strong>
                    </v-flex>
                    <v-flex xs9>
                      <v-select
                        id="inputs-state"
                        v-model="inputs.state"
                        :items="['Enable', 'Disable']"
                        label="State"
                        outline
                        :rules="[validators.required]"
                        :disabled="loading"
                        hide-details
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout xs12 d-flex row mt-4 v-if="inputs.state == 'Enable'">
                    <v-flex xs3 align-center d-flex>
                      <strong class="mr-2">Action:</strong>
                    </v-flex>
                    <v-flex xs9>
                      <v-select
                        id="inputs-state"
                        v-model="inputs.action"
                        :items="['Add time', 'Set time']"
                        label="Action"
                        outline
                        :rules="[validators.required]"
                        :disabled="loading"
                        hide-details
                      />
                    </v-flex>
                  </v-layout>
                  <template v-if="inputs.action == 'Add time'">
                    <v-layout xs12 d-flex row mt-4>
                      <v-flex xs3 align-start d-flex>
                        <strong class="mr-2">Time:</strong>
                      </v-flex>
                      <v-flex xs9>
                        <VTextField
                          v-model="inputs.time.years"
                          outline
                          autocomplete="false"
                          type="number"
                          label="Year(s)"
                          :rules="[validators.required, inputs.time.years ? (v) => validators.maxNumber(v, 10000) : () => true]"
                          menu="set-state"
                          min="1"
                          @keypress="
                            (e) => {
                              if (e.keyCode > 57 || e.keyCode < 48) e.preventDefault();
                            }
                          "
                        />
                      </v-flex>
                    </v-layout>
                    <v-layout xs12 d-flex row mt-4>
                      <v-flex xs3 align-center d-flex> </v-flex>
                      <v-flex xs9>
                        <VTextField
                          v-model="inputs.time.months"
                          outline
                          autocomplete="false"
                          type="number"
                          label="Month(s)"
                          :rules="[validators.required, inputs.time.months ? (v) => validators.maxNumber(v, 10000) : () => true]"
                          menu="set-state"
                          min="1"
                          @keypress="
                            (e) => {
                              if (e.keyCode > 57 || e.keyCode < 48) e.preventDefault();
                            }
                          "
                        />
                      </v-flex>
                    </v-layout>
                    <v-layout xs12 d-flex row mt-4>
                      <v-flex xs3 align-center d-flex> </v-flex>
                      <v-flex xs9>
                        <VTextField
                          v-model="inputs.time.days"
                          outline
                          autocomplete="false"
                          type="number"
                          label="Day(s)"
                          :rules="[validators.required, inputs.time.days ? (v) => validators.maxNumber(v, 10000) : () => true]"
                          menu="set-state"
                          min="1"
                          @keypress="
                            (e) => {
                              if (e.keyCode > 57 || e.keyCode < 48) e.preventDefault();
                            }
                          "
                        />
                      </v-flex>
                    </v-layout>
                  </template>
                  <template v-if="inputs.action == 'Set time'">
                    <v-layout xs12 d-flex row mt-4 flex-wrap>
                      <v-flex xs12>
                        <VDatePicker class="elevation-0" scrollable v-model="inputs.time.date" no-title reactive />
                        <v-time-picker
                          v-model="inputs.time.clock"
                          scrollable
                          full-width
                          color="green darken-1"
                          class="flex d-inline-block mr-2 ml-2"
                          style="width: 230px;"
                        ></v-time-picker>
                      </v-flex>
                    </v-layout>
                  </template>
                </template>
                <v-layout v-if="menu == 'table-summary' && !loading" xs12 d-flex column class="table-container">
                  <v-flex v-if="submitted" align-start d-flex mb-4>
                    <strong v-html="lastStepText"></strong>
                  </v-flex>
                  <table class="v-datatable v-table theme--light">
                    <thead>
                      <tr>
                        <th class="column text-xs-center">
                          Device ID
                        </th>
                        <th class="column text-xs-center">
                          Description
                        </th>
                        <th class="column text-xs-center">
                          Registration Date
                        </th>
                        <th class="column text-xs-center">
                          Manager
                        </th>
                        <th class="column text-xs-center multiheader px-0">
                          <tr>
                            <td colspan="2">{{ submitted && !error ? `Updated` : `Current` }} Subscription</td>
                          </tr>
                          <tr>
                            <td>Remaining Days</td>
                            <td>Expiry Date</td>
                          </tr>
                        </th>

                        <th v-if="!submitted" class="column text-xs-center multiheader px-0">
                          <tr>
                            <td colspan="2">New Subscription</td>
                          </tr>
                          <tr>
                            <td>Remaining Days</td>
                            <td>Expiry Date</td>
                          </tr>
                        </th>

                        <th v-if="submitted" class="column text-xs-center">
                          Status
                        </th>
                      </tr>
                      <tr class="v-datatable__progress" v-if="loading">
                        <th colspan="8" class="column">
                          <div role="progressbar" aria-valuemin="0" aria-valuemax="100" class="v-progress-linear" style="height: 2px;">
                            <div class="v-progress-linear__background primary" style="height: 2px; opacity: 0.3; width: 100%;"></div>
                            <div class="v-progress-linear__bar">
                              <div class="v-progress-linear__bar__indeterminate v-progress-linear__bar__indeterminate--active">
                                <div class="v-progress-linear__bar__indeterminate long primary"></div>
                                <div class="v-progress-linear__bar__indeterminate short primary"></div>
                              </div>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-for="(device, index) in devices" :key="index">
                      <tr>
                        <td>{{ device.identifier }}</td>
                        <td>{{ device.description }}</td>
                        <td>
                          {{ $moment.unix(device.start_timestamp).format("YYYY-MM-DD HH:mm:ss") }}
                        </td>
                        <td>
                          {{ device.owner && device.owner.owner_username ? device.owner.owner_username : "" }}
                        </td>
                        <th class="column text-xs-center multiheader px-0">
                          <tr>
                            <td>
                              {{ device.state == 0 ? "Disabled" : device.remaining > 0 ? device.remaining : 0 }}
                            </td>
                            <td>
                              {{ device.expiry_date }}
                            </td>
                          </tr>
                        </th>

                        <th v-if="!submitted" class="column text-xs-center multiheader px-0">
                          <tr>
                            <td>
                              {{ inputs.state == "Disable" ? "Disabled" : device.new_remaining > 0 ? device.new_remaining : 0 }}
                            </td>
                            <td>
                              {{ inputs.state == "Disable" ? device.expiry_date : device.new_expiry_date }}
                            </td>
                          </tr>
                        </th>

                        <td v-if="submitted">
                          <v-icon v-if="device.isUpdateSuccess" color="success">check</v-icon>
                          <v-icon v-else color="red">close</v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-layout>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-divider class="ma-auto"></v-divider>
          <v-card-actions v-if="menu != null && menu !== 'insert-manager-name'" class="pa-3 flex flex-wrap">
            <v-btn v-if="menu != null && !submitted && (!!id || !!lookup)" color="gray" flat="flat" :disabled="loading" @click="goBack()">
              BACK
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              v-if="(menu == 'set-state' && inputs.state == 'Disable') || (menu == 'set-state' && inputs.state == 'Enable') || menu !== 'set-state'"
              color="green"
              flat="flat"
              :disabled="
                loading ||
                  (menu == 'insert-device-ids' && !inputs.ids) ||
                  (menu == 'set-state' && epochDateCheck < 1 && inputs.state !== 'Disable') ||
                  (menu == 'insert-manager-name' && !search) ||
                  (menu == 'device-table' && selectedDevices.length < 1) ||
                  (menu == 'table-summary' && devices.length === 0)
              "
              @click="menu == 'table-summary' && !submitted ? submit() : error ? submit(true) : goNext()"
            >
              {{ nextButtonText }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import validators from "@/library/helpers/validators";
import DeviceTable from "@/views/owner/ConfigureDeviceSubscription/components/DeviceTable.vue";
import { mapState } from "vuex";
import SearchCard from "./components/SearchCard.vue";
import Api from "@/library/apis/Api";

export default {
  name: "DeviceSubscriptionTime",
  components: {
    DeviceTable,
    SearchCard,
  },
  data() {
    return {
      lookup: false,
      id: false,
      menu: null,
      selectedDevices: [],
      search: null,
      error: null,
      loading: false,
      inputs: {
        ids: null,
        state: null,
        action: null,
        time: {
          years: null,
          months: null,
          days: null,
          date: null,
          clock: "23:59",
        },
      },
      step: 0,
      submitted: false,
      devices: [],
    };
  },
  computed: {
    validators: () => validators,
    ...mapState({
      managerview: (state) => state.Admin.managerview,
    }),
    nextButtonText() {
      if (!this.submitted) {
        if (this.menu !== "table-summary") return "Next";
        else return "Confirm";
      }
      if (this.submitted) {
        if (this.error) return "Retry Failures";
        else return "Back To Start";
      }
    },
    deviceIds() {
      const existingIds = ((this.inputs.ids || "").split(/[,\s\n]/) || []).filter((s) => s !== null && s !== "");
      if (this.selectedDevices.length === 0) {
        return existingIds;
      }

      const ids = [...new Set([...existingIds, ...this.selectedDevices])];

      return ids;
    },
    lastStepText() {
      if (!this.submitted) return "Please confirm changes:";
      if (this.error) return `<span class="red--text">Error!</span> ${this.error}`;
      return `<span class="success--text">Success!</span> Subscription has been updated!`;
    },
    epochDateCheck() {
      return this.epochDate(0);
    },
  },
  watch: {
    "inputs.state"() {
      this.inputs.action = null;
    },
    "inputs.action"() {
      this.inputs.time = {
        years: null,
        months: null,
        days: null,
        date: null,
        clock: "23:59",
      };
    },
  },
  methods: {
    goSearch() {
      this.loading = true;
      this.error = null;
      this.lookup = true;
      this.search = this.search.trim();
      this.$store.dispatch("resetState");
      this.$store.dispatch("Admin/resetState");

      Api.adminOwner({ ownerUsername: this.search })
        .then((owners) => {
          if (owners.length > 0) {
            const owner = owners[0];
            this.$store.commit("Admin/setSearchedUsers", this.search);
            this.$store.commit("Admin/setState", { managerview: owner });
            this.$store.dispatch("countData");
            this.loading = false;

            this.$nextTick(() => {
              this.$store.dispatch("Admin/getPreferencesForView");
              this.menu = "device-table";
            });
          } else {
            this.error = "Manager Does Not Exist";
          }
        })
        .catch((error) => {
          this.error = "Manager Does Not Exist";
          this.loading = false;
        });
    },
    epochDate(unix) {
      if (this.inputs.action === "Set time") {
        if (!this.inputs.time.clock || !this.inputs.time.date) return 0;
        const combinedDateTime = `${this.inputs.time.date} ${this.inputs.time.clock}`;
        return this.$moment(combinedDateTime, "YYYY-MM-DD HH:mm").unix();
      }
      if (this.inputs.action == "Add time") {
        if (!this.inputs.time.years && !this.inputs.time.months && !this.inputs.time.days) return 0;
        let date = this.$moment.unix(unix).startOf("day");
        if (this.inputs.time.years && this.inputs.time.years > 0) date.add(this.inputs.time.years, "years");
        if (this.inputs.time.months && this.inputs.time.months > 0) date.add(this.inputs.time.months, "months");
        if (this.inputs.time.days && this.inputs.time.days > 0) date.add(this.inputs.time.days, "days");
        return date.unix();
      }
      return 0;
    },
    getPlugs() {
      this.devices = [];
      this.loading = true;
      let requests = [];

      for (let i = 0; i < this.deviceIds.length; i++) {
        const plugIdentifier = this.deviceIds[i];
        requests.push(Api.plug("admin", { plugIdentifier }));
      }

      Promise.all(requests)
        .then((responses) => {
          for (let i = 0; i < responses.length; i++) {
            const device = responses[i][0];
            if (device) {
              device.isUpdateSuccess = false;
              device.owner = null;
              device.expiry_date = this.$moment.unix(device.expiry_timestamp).format("YYYY-MM-DD HH:mm:ss");
              const epochDate = this.epochDate(device.expiry_timestamp);
              const remaining = this.$moment.unix(device.expiry_timestamp).unix() - this.$moment().unix();
              device.remaining = Math.floor(remaining / (24 * 3600));

              if (this.inputs.state === "Enable") {
                if (this.inputs.action === "Add time") {
                  device.new_remaining =
                    device.remaining +
                    Math.floor(
                      (epochDate -
                        this.$moment
                          .unix(device.expiry_timestamp)
                          .startOf("day")
                          .unix()) /
                        (24 * 3600)
                    );
                }

                if (this.inputs.action === "Set time") {
                  device.remaining = device.remaining;
                  const new_remaining = epochDate - this.$moment().unix(device.expiry_timestamp);
                  device.new_remaining = Math.floor(new_remaining / (24 * 3600));
                }
              }

              let new_expiry_from = this.inputs.state === "Enable" && this.inputs.action === "Add time" ? device.expiry_timestamp : this.epochDate();
              if (this.inputs.action === "Add time") {
                device.new_expiry_epoch = this.$moment
                  .unix(new_expiry_from)
                  .add(device.new_remaining > device.remaining ? device.new_remaining - device.remaining : device.new_remaining, "days")
                  .unix();
              }
              if (this.inputs.action === "Set time") {
                device.new_expiry_epoch = this.$moment.unix(new_expiry_from).unix();
              }

              device.new_expiry_date = this.$moment.unix(device.new_expiry_epoch).format("YYYY-MM-DD HH:mm:ss");
              this.devices.push(device);
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.loading = false;
          this.getOwners();
          this.$nextTick(() => this.goNext());
        });
    },
    getOwners() {
      const owners = [];
      const ownerIds = this.devices.map((d) => d.owner_id).filter((value, index, self) => self.indexOf(value) === index);
      let requests = [];
      for (let i = 0; i < ownerIds.length; i++) {
        const ownerId = ownerIds[i];
        if (!ownerId) continue;
        requests.push(Api.adminOwner({ ownerId }));
      }
      Promise.all(requests)
        .then((responses) => {
          for (let i = 0; i < responses.length; i++) {
            const owner = responses[i][0];
            if (owner) owners.push(owner);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          for (let i = 0; i < this.devices.length; i++) {
            const device = this.devices[i];
            device.owner = owners.find((o) => o && o.id == device.owner_id);
          }
        });
    },
    async submit(only_failed = false) {
      this.loading = true;
      const state = this.inputs.state == "Enable" ? 1 : 0;
      for (let i = 0; i < this.devices.length; i++) {
        const device = this.devices[i];
        if (only_failed && device.isUpdateSuccess) continue;
        const plugId = device.id;
        const expiry_epoch = state === 1 ? device.new_expiry_epoch : 0;
        const requests = [Api.plugSetState("admin", { state, plugId })];
        if (state === 1) requests.push(Api.plugSetExpiry("admin", { expiry: expiry_epoch, plugId }));
        await Promise.all(requests)
          .then((responses) => {
            this.devices[i].isUpdateSuccess = true;
            this.devices[i].state = state;
            if (state === 1) {
              this.devices[i].expiry_timestamp = expiry_epoch;
              this.devices[i].expiry_date = this.$moment.unix(expiry_epoch).format("YYYY-MM-DD HH:mm:ss");
              this.devices[i].remaining = device.new_remaining;
            }
          })
          .catch((err) => {
            this.devices[i].isUpdateSuccess = false;
            this.error = "Failed to set device(s) state or expiry";
          });
      }
      this.submitted = true;
      this.loading = false;
    },
    goBack() {
      switch (this.menu) {
        case "insert-device-ids":
          this.id = false;
          this.menu = null;
          break;
        case "insert-manager-name":
          this.lookup = false;
          this.menu = null;
          break;
        case "set-state":
          if (this.id) {
            this.reset();
            this.menu = "insert-device-ids";
          } else if (this.lookup) {
            this.menu = "device-table";
          }
          break;
        case "table-summary":
          this.menu = "set-state";
          break;
        case "device-table":
          this.reset();
          this.menu = "insert-manager-name";
          break;
      }
    },
    goNext(btnId) {
      if (this.menu === null) {
        switch (btnId) {
          case "device-ids":
            this.menu = "insert-device-ids";
            this.id = true;
            break;
          case "lookup-manager":
            this.menu = "insert-manager-name";
            this.lookup = true;
            break;
        }
      } else {
        switch (this.menu) {
          case "insert-manager-name":
            this.goSearch();
            break;
          case "device-table":
            this.inputs.ids = this.deviceIds.join(",");
            this.menu = "set-state";
            break;
          case "set-state":
            this.getOwners();
            this.getPlugs();
            this.menu = "table-summary";
            break;
          case "table-summary":
            if (this.submitted) {
              this.$router.push("/admin/configure-device-subscriptions-time");
              this.menu = null;
              this.reset();
            }
            break;

          case "insert-device-ids":
            this.menu = "set-state";
            break;
          case "set-state":
            this.getPlugs();
            this.menu = "table-summary";
            break;
          case "table-summary":
            if (this.submitted) {
              this.$router.push("/admin/configure-device-subscriptions-time");
              this.menu = null;
              this.reset();
            }
            break;
        }
      }
    },
    reset() {
      this.menu = null;
      this.search = null;
      this.selectedDevices = [];
      this.devices = [];
      this.error = null;
      this.loading = false;
      this.submitted = false;
      this.inputs = {
        ids: null,
        state: null,
        action: null,
        time: {
          years: null,
          months: null,
          days: null,
          date: null,
          clock: "23:59",
        },
      };
    },
  },
  mounted() {
    if (this.$route.query.device) {
      this.inputs.ids = this.$route.query.device;
      this.$nextTick(() => {
        this.goNext();
      });
    }
  },
};
</script>

<style scoped lang="scss">
.v-datatable {
  th {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    &:first-child {
      border-left: 0px;
    }
  }
  tr {
    border: 1px solid rgba(0, 0, 0, 0.12);
    td {
      border-left: 1px solid rgba(0, 0, 0, 0.12);
      &:first-child {
        border-left: 0px;
      }
    }
  }
  th.multiheader {
    tr {
      border: 0px;
      height: 27px;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      display: flex;
      align-items: center;
      justify-content: center;
      &:first-child {
        border-top: 0px;
        width: 100%;
      }
      td {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
      }
    }
  }
}
.table-container {
  overflow-x: scroll;
}
</style>
