<template>
  <v-layout xs12 row justify-center align-center class="h-full">
    <v-flex md6 sm12 xs12>
      <SearchCard
        :error="error"
        :loading="loading"
        v-model="search"
        @search="goSearch"
        @input="(v) => (search = v)"
        title="Lookup Devices"
        inputLabel="Enter Device ID"
        searchedListTitle="Searched Devices:"
        :forceLowerCase="false"
      />
    </v-flex>
  </v-layout>
</template>
<script>
import Api from "@/library/apis/Api";
import { mapState } from "vuex";
import { DeviceHelpers } from '@/library/helpers';
import SearchCard from "./../components/SearchCard";
export default {
  name: "DeviceView",
  components: {
    SearchCard,
  },
  data() {
    return {
      error: null,
      search: null,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      deviceview: (state) => state.Admin.deviceview,
    }),
  },
  methods: {
    goSearch() {
      this.error = null;
      this.search = this.search.trim();
      this.$store.dispatch("loading", true);
      this.$store.commit("Admin/setDeviceView", null);
      this.$store.dispatch("resetState");
      this.$store.dispatch("Admin/resetState");
      
      Api.plug("admin", { plugIdentifier: this.search })
        .then(async (plugs) => {
          if (plugs.length === 0 || (!plugs[0] && plugs[0] !== null)) {
            this.error = "Device Does Not Exist";
            this.$store.dispatch("loading", false);
            return;
          }

          let plug = plugs[0] || { identifier: this.search, id: "not_registered" };
          plug.owner_username = null;

          const requests = [Api.adminManufacturedPlugFetch({ qr: plug.identifier })]
          if (!!plug.owner_id) requests.push(Api.adminOwner({ ownerId: plug.owner_id }))

          requests.push(Api.adminPlugGetDataplanConfig({ plugIdentifier: plug.identifier }));

          await Promise.all(requests)
            .then(async (response) => {
              const manufacturedPlug = response[0]
              if (!manufacturedPlug.id) {
                this.error = "Device Does Not Exist"
                this.$store.dispatch("loading", false); 
                return;
              }

              let owners = []
              if (!!plug.owner_id) owners = response[1]

              if (owners.length > 0) {
                plug.owner_username = owners[0].owner_username;

                const prefsResponse = await Api.getPrefs("admin/owner", { ownerUsername: plug.owner_username }).catch(() => null);
                const ownerPrefs = prefsResponse || {};
                plug.ownerPrefs = ownerPrefs;
              }


              let dataplanConfigData = response[2] || {};
              plug.dataplanConfig = dataplanConfigData;

              if (manufacturedPlug) {
                const payload = new FormData()
                payload.append("domain", manufacturedPlug.internal_identifier)
                const logs = await Api.lastLog(payload)
                const last_log = logs ? logs[0] : null

                plug = {
                  ...manufacturedPlug,
                  ...plug,
                  ...DeviceHelpers.parseSerialNumber(manufacturedPlug.serial_no),
                  inventory_status_text: DeviceHelpers.parseInventoryStatus(manufacturedPlug.inventory_status),
                  manufactured_date: manufacturedPlug.registration_time,
                  last_log
                }
              }

              this.$store.commit("Admin/setSearchedUsers", this.search);
              this.$store.commit("Admin/setDeviceView", plug);
              if (plug.id !== "not_registered") this.$store.dispatch("countData");
              this.$router.push("/admin/deviceview-dashboard");
            })
            .catch(() => {
              this.error = "Device Does Not Exist";
            })
            .finally(() => {
              this.$store.dispatch("loading", false);
            })
        })
        .catch(() => {
          this.error = "Device Does Not Exist";
        })
        .finally(() => {
          this.$store.dispatch("loading", false);
        })
    },
  },
  mounted() {
    if (this.$route.query.device) {
      this.search = this.$route.query.device
      this.$nextTick(() => {
        this.goSearch()
      })
    }
  },
};
</script>
<style lang="scss">
.h-full {
  height: 100%;
}
</style>
