<template>
  <v-container fluid class="fill-height text-xs-center full-inner-height">
    <v-layout align-center justify-center>
      <v-flex
        xs12
        :md6="[null, 'insert-device-ids', 'set-initial-duration', 'configure-subscription-plan', 'insert-manager-name'].includes(menu)"
        :md12="['device-table', 'table-summary'].includes(menu)"
      >
        <SearchCard
          v-if="menu == 'insert-manager-name'"
          :error="error"
          :loading="loading"
          v-model="search"
          @search="goSearch"
          @input="(v) => (search = v)"
          title="Configure Device Subscription Plan"
          inputLabel="Enter Manager E-mail"
          searched-list-title="Searched Managers:"
          is-configure-subscription
          @back="goBack()"
          @next="goNext()"
        />
        <v-card v-else>
          <v-card-title class="flex">
            <h3>{{ !submitted ? "Configure Device Subscriptions Plan" : "Configure Device Subscription" }}</h3>
          </v-card-title>
          <v-alert :value="error" type="error" dismissible>
            {{ error }}
          </v-alert>
          <v-card-text>
            <v-flex xs12 v-if="!!loading">
              <v-progress-circular class="loading-circle center-margin" size="50" indeterminate color="orange" />
            </v-flex>
            <v-layout v-if="menu === null" xs12 class="py-4 my-4" justify-content-start align-center flex column>
              <v-btn
                v-model="id"
                id="device-ids"
                small
                class="plugzio-button create-button py-2 px-2 my-2 mx-2"
                style="width: 220px; height: 50%; border-radius: 10px;"
                round
                outline
                @click="goNext('device-ids')"
              >
                <strong>ENTER DEVICE IDs</strong>
              </v-btn>
              <v-btn
                v-model="lookup"
                id="lookup-manager"
                small
                class="plugzio-button create-button py-2 px-2 my-2 mx-2"
                style="width: 220px; height: 70%; border-radius: 10px;"
                round
                outline
                @click="goNext('lookup-manager')"
              >
                <strong>Lookup Devices By Manager</strong>
              </v-btn>
            </v-layout>
            <v-text-field
              v-if="menu == 'insert-manager-name'"
              v-model="search"
              outline
              :rules="[validators.required]"
              label="Enter Manager E-Mail"
              autocomplete="false"
              required
              @keyup.enter="goSearch()"
              :disabled="loading"
            ></v-text-field>
            <DeviceTable
              v-if="menu == 'device-table'"
              hide-search-bar
              hide-filter
              hide-select-all
              allow-selection
              hide-auto-payment
              :selected="selectedDevices"
              @selected="selectedDevices = $event"
            />
            <v-form ref="form" v-else-if="!loading">
              <v-layout xs12 justify-content-start d-flex column>
                <template v-if="menu == 'insert-device-ids'">
                  <strong class="d-flex">Enter Device IDs:</strong>
                  <v-textarea
                    id="inputs-device-ids"
                    class="mt-1"
                    v-model="inputs.ids"
                    outline
                    :rules="[validators.required, (v) => validators.maxCharacter(v, 1000)]"
                    counter="1000"
                    rows="10"
                    auto-grow
                    label="Device IDs"
                  />
                </template>
                <template v-if="menu == 'set-initial-duration'">
                  <v-layout xs12 d-flex column mt-4>
                    <v-flex xs3 text-xs-left d-flex>
                      <h3 style="margin-left: 30px;"><strong>Initial Subscription Duration:</strong></h3>
                    </v-flex>
                    <v-flex xs9 justify-end>
                      <VTextField
                        v-model="inputs.time.years"
                        outline
                        autocomplete="false"
                        type="number"
                        label="Year(s)"
                        :rules="[validators.required, validators.allowZero, (v) => validators.maxNumber(v, 10000)]"
                        step="1"
                        min="0"
                        class="mt-3"
                        style="width: 60%; margin-left: auto; margin-right: auto;"
                        @keypress="
                          (e) => {
                            if (e.keyCode > 57 || e.keyCode < 48) e.preventDefault();
                          }
                        "
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout xs12 d-flex column mt-2>
                    <v-flex xs3 text-xs-left d-flex></v-flex>
                    <v-flex xs9 justify-end>
                      <VTextField
                        v-model="inputs.time.months"
                        outline
                        autocomplete="false"
                        type="number"
                        label="Month(s)"
                        min="0"
                        :rules="[validators.required, validators.allowZero, (v) => validators.maxNumber(v, 10000)]"
                        step="1"
                        class="mt-2"
                        style="width: 60%; margin-left: auto; margin-right: auto;"
                        @keypress="
                          (e) => {
                            if (e.keyCode > 57 || e.keyCode < 48) e.preventDefault();
                          }
                        "
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout xs12 d-flex column mt-2>
                    <v-flex xs3 text-xs-left d-flex></v-flex>
                    <v-flex xs9 justify-end>
                      <VTextField
                        v-model="inputs.time.days"
                        outline
                        autocomplete="false"
                        type="number"
                        label="Day(s)"
                        min="0"
                        :rules="[validators.required, validators.allowZero, (v) => validators.maxNumber(v, 10000)]"
                        step="1"
                        class="mt-2"
                        style="width: 60%; margin-left: auto; margin-right: auto;"
                        @keypress="
                          (e) => {
                            if (e.keyCode > 57 || e.keyCode < 48) e.preventDefault();
                          }
                        "
                      />
                    </v-flex>
                  </v-layout>
                </template>
                <template v-if="menu == 'configure-subscription-plan'">
                  <v-layout xs12 d-flex column mt-2>
                    <v-flex xs3 text-xs-left>
                      <h3 style="margin-left: 30px;"><strong>Monthly Fee:</strong></h3>
                    </v-flex>
                    <v-flex xs9 justify-end>
                      <VTextField
                        v-model.number="inputs.monthlyUsd"
                        label="US Dollars"
                        outline
                        type="number"
                        min="0"
                        :rules="[validators.required, validators.allowZero, validators.twoDecimalNumber]"
                        :disabled="loading"
                        class="mt-3"
                        suffix="$"
                        style="width: 60%; margin-left: auto; margin-right: auto;"
                        @input="(v) => formatToDecimal(v, 'monthlyUsd')"
                        @blur="formatDecimal('monthlyUsd')"
                      />
                      <VTextField
                        v-model.number="inputs.monthlyCad"
                        label="Canadian Dollars"
                        outline
                        type="number"
                        min="0"
                        :rules="[validators.required, validators.allowZero, validators.twoDecimalNumber]"
                        :disabled="loading"
                        class="mt-2"
                        suffix="$"
                        style="width: 60%; margin-left: auto; margin-right: auto;"
                        @input="(v) => formatToDecimal(v, 'monthlyCad')"
                        @blur="formatDecimal('monthlyCad')"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout xs12 d-flex column mt-4>
                    <v-flex xs3 text-xs-left d-flex>
                      <h3 style="margin-left: 30px;"><strong>Pay As You Go Fee:</strong></h3>
                    </v-flex>
                    <v-flex xs9 justify-end>
                      <VTextField
                        v-model.number="inputs.paygUsd"
                        label="US Dollars"
                        outline
                        type="number"
                        min="0"
                        :rules="[validators.required, validators.allowZero, validators.twoDecimalNumber]"
                        :disabled="loading"
                        class="mt-3"
                        suffix="$"
                        style="width: 60%; margin-left: auto; margin-right: auto;"
                        @input="(v) => formatToDecimal(v, 'paygUsd')"
                        @blur="formatDecimal('paygUsd')"
                      />
                      <VTextField
                        v-model.number="inputs.paygCad"
                        label="Canadian Dollars"
                        outline
                        type="number"
                        min="0"
                        :rules="[validators.required, validators.allowZero, validators.twoDecimalNumber]"
                        :disabled="loading"
                        class="mt-2"
                        suffix="$"
                        style="width: 60%; margin-left: auto; margin-right: auto;"
                        @input="(v) => formatToDecimal(v, 'paygCad')"
                        @blur="formatDecimal('paygCad')"
                      />
                      <template>
                        <div class="mt-2 ml-5" style="display: flex; align-items: center;">
                          <span class="text-xs-subtitle-1">Advanced Settings</span>
                          <v-btn flat small icon class="ma-0" @click.stop="showAdvanced = !showAdvanced">
                            <v-icon v-if="!showAdvanced" class="up-arrow-icon">arrow_left</v-icon>
                            <v-icon v-else class="down-arrow-icon" style="color: green;">arrow_drop_down</v-icon>
                          </v-btn>
                        </div>
                        <div v-if="showAdvanced" class="ml-5 align-items-baseline mb-0">
                          <v-select
                            class="mt-1 ml-2"
                            style="width: 78%;"
                            align-center
                            v-model="selectedSetting"
                            outline
                            :items="settingOptions"
                            label=""
                            item-value="value"
                            item-text="label"
                            :rules="[validators.required]"
                          />
                          <span class="text-left d-flex mt-0 ml-3" style="font-weight: bold; font-size: small;">Pay As You Go Start Date</span>
                        </div>
                        <v-flex xs12 class="mt-2" v-if="selectedSetting === 'custom'">
                          <VDatePicker class="elevation-2" scrollable v-model="inputs.time.date" no-title reactive />
                          <v-time-picker
                            v-model="inputs.time.clock"
                            scrollable
                            full-width
                            color="green darken-1"
                            class="flex d-inline-block mr-2 ml-2"
                            style="width: 230px;"
                          ></v-time-picker>
                        </v-flex>
                      </template>
                    </v-flex>
                  </v-layout>
                </template>
                <v-layout v-if="menu == 'table-summary' && !loading" xs12 d-flex column class="table-container">
                  <v-flex v-if="submitted" align-start d-flex mb-4>
                    <strong v-html="lastStepText"></strong>
                  </v-flex>
                  <table class="v-datatable v-table theme--light">
                    <thead>
                      <tr>
                        <th class="column text-xs-center">
                          Device ID
                        </th>
                        <th class="column text-xs-center">
                          Description
                        </th>
                        <th v-if="!submitted" class="column text-xs-center multiheader px-0">
                          <tr>
                            <td colspan="8">Current Subscription Plan</td>
                          </tr>
                          <tr>
                            <td class="w-1/4" colspan="2">Monthly Fee</td>
                            <td class="w-1/2" colspan="3">Pay As You Go Fee</td>
                            <td class="w-1/4">Initial Subscription Time</td>
                          </tr>
                          <tr>
                            <td class="w-1/8">USD</td>
                            <td class="w-1/8">CAD</td>
                            <td class="w-1/8">USD</td>
                            <td class="w-1/8">CAD</td>
                            <td class="w-2/8">Start Time</td>
                            <td class="w-2/8">(Days)</td>
                          </tr>
                        </th>

                        <th class="column text-xs-center multiheader px-0">
                          <tr>
                            <td colspan="8">{{ submitted && !error ? `Updated` : `New` }} Subscription Plan</td>
                          </tr>
                          <tr>
                            <td class="w-1/4" colspan="2">Monthly Fee</td>
                            <td class="w-1/2" colspan="3">Pay As You Go Fee</td>
                            <td class="w-1/4">Initial Subscription Time</td>
                          </tr>
                          <tr>
                            <td class="w-1/8">USD</td>
                            <td class="w-1/8">CAD</td>
                            <td class="w-1/8">USD</td>
                            <td class="w-1/8">CAD</td>
                            <td class="w-2/8">Start Time</td>
                            <td class="w-2/8">(Days)</td>
                          </tr>
                        </th>

                        <th v-if="submitted" class="column text-xs-center w-1/12">
                          Status
                        </th>
                      </tr>
                      <tr class="v-datatable__progress" v-if="loading">
                        <th colspan="8" class="column">
                          <div role="progressbar" aria-valuemin="0" aria-valuemax="100" class="v-progress-linear" style="height: 2px;">
                            <div class="v-progress-linear__background primary" style="height: 2px; opacity: 0.3; width: 100%;"></div>
                            <div class="v-progress-linear__bar">
                              <div class="v-progress-linear__bar__indeterminate v-progress-linear__bar__indeterminate--active">
                                <div class="v-progress-linear__bar__indeterminate long primary"></div>
                                <div class="v-progress-linear__bar__indeterminate short primary"></div>
                              </div>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-for="(device, index) in devices" :key="index">
                      <tr>
                        <td>{{ device.identifier }}</td>
                        <td>{{ device.description }}</td>
                        <td v-if="!submitted" class="pa-0">
                          <tr class="ma-0 pa-0 d-flex" style="border:0;">
                            <td class="w-1/8 d-flex align-center justify-center">
                              {{ "$ " + parseFloat(formatValue(device.monthlyUsd || "0").replace(/,/g, "")).toFixed(2) }}
                            </td>
                            <td class="w-1/8 d-flex align-center justify-center">
                              {{ "$ " + parseFloat(formatValue(device.monthlyCad || "0").replace(/,/g, "")).toFixed(2) }}
                            </td>
                            <td class="w-1/8 d-flex align-center justify-center">
                              {{ "$ " + parseFloat(formatValue(device.paygUsd || "0").replace(/,/g, "")).toFixed(2) }}
                            </td>
                            <td class="w-1/8 d-flex align-center justify-center">
                              {{ "$ " + parseFloat(formatValue(device.paygCad || "0").replace(/,/g, "")).toFixed(2) }}
                            </td>
                            <td class="w-2/8 d-flex align-center justify-center">
                              {{ device && device.paygStart != null ? $moment.unix(device.paygStart).format("YYYY-MM-DD hh:mm:ss") : "" }}
                            </td>
                            <td class="w-2/8 d-flex align-center justify-center">
                              {{ device && device.existing_initial_subscription_time_days > 0 ? device.existing_initial_subscription_time_days : 0 }}
                            </td>
                          </tr>
                        </td>

                        <td class="pa-0">
                          <tr class="ma-0 pa-0 d-flex" style="border:0">
                            <td class="w-1/8 d-flex align-center justify-center">
                              {{ "$ " + parseFloat(formatValue(device.new_monthlyUsd || "0").replace(/,/g, "")).toFixed(2) }}
                            </td>
                            <td class="w-1/8 d-flex align-center justify-center">
                              {{ "$ " + parseFloat(formatValue(device.new_monthlyCad || "0").replace(/,/g, "")).toFixed(2) }}
                            </td>
                            <td class="w-1/8 d-flex align-center justify-center">
                              {{ "$ " + parseFloat(formatValue(device.new_paygUsd || "0").replace(/,/g, "")).toFixed(2) }}
                            </td>
                            <td class="w-1/8 d-flex align-center justify-center">
                              {{ "$ " + parseFloat(formatValue(device.new_paygCad || "0").replace(/,/g, "")).toFixed(2) }}
                            </td>
                            <td class="w-2/8 d-flex align-center justify-center">
                              {{ device && device.newPaygStart != null ? $moment.unix(device.newPaygStart).format("YYYY-MM-DD hh:mm:ss") : "" }}
                            </td>
                            <td class="w-2/8 d-flex align-center justify-center">
                              {{
                                device && device.new_initial_subscription_time_days > 0
                                  ? device.new_initial_subscription_time_days
                                  : device.new_initial_subscription_time_days < 0
                                  ? "Disabled"
                                  : 0
                              }}
                            </td>
                          </tr>
                        </td>

                        <td v-if="submitted" class="w-1/12 align-center justify-center">
                          <v-icon v-if="device.isUpdateSuccess" color="success">check</v-icon>
                          <v-icon v-else color="red">close</v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-layout>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-divider class="ma-auto"></v-divider>
          <v-card-actions v-if="menu != null && menu !== 'insert-manager-name'" class="pa-3 flex flex-wrap">
            <v-btn v-if="menu != null && !submitted && (!!id || !!lookup)" color="gray" flat="flat" :disabled="loading" @click="goBack()">
              BACK
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="green"
              flat="flat"
              :disabled="
                loading ||
                  (menu == 'insert-device-ids' && !inputs.ids) ||
                  (menu == 'set-initial-duration' && (!inputs.time.years || !inputs.time.months || !inputs.time.days)) ||
                  (menu == 'configure-subscription-plan' &&
                    (!inputs.monthlyCad || !inputs.monthlyUsd || !inputs.paygCad || !inputs.paygUsd || (selectedSetting === 'custom' && !inputs.time.date))) ||
                  !selectedSetting ||
                  (menu == 'insert-manager-name' && !search) ||
                  (menu == 'device-table' && selectedDevices.length < 1) ||
                  (menu == 'table-summary' && devices.length === 0)
              "
              @click="menu == 'table-summary' && !submitted ? submit() : error ? submit(true) : goNext()"
            >
              {{ nextButtonText }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import validators from "@/library/helpers/validators";
import { mapState } from "vuex";
import DeviceTable from "@/views/owner/ConfigureDeviceSubscription/components/DeviceTable.vue";
import Api from "@/library/apis/Api";
import { formatDecimal, isDecimalExceedLimit, stringToDecimal } from "@/library/helpers";
import SearchCard from "./components/SearchCard.vue";

export default {
  name: "DeviceSubscriptionPlan",
  components: {
    DeviceTable,
    SearchCard,
  },
  data() {
    return {
      search: null,
      lookup: false,
      id: false,
      selectedDevices: [],
      menu: null,
      error: null,
      loading: false,
      inputs: {
        ids: null,
        monthlyCad: null,
        monthlyUsd: null,
        paygCad: null,
        paygUsd: null,
        time: {
          years: null,
          months: null,
          days: null,
          date: null,
          clock: "23:59",
        },
      },
      submitted: false,
      devices: [{}],
      showAdvanced: false,
      selectedSetting: "expiry",
    };
  },
  computed: {
    ...mapState({
      managerview: (state) => state.Admin.managerview,
    }),
    nextButtonText() {
      if (!this.submitted) {
        if (this.menu !== "table-summary") return "Next";
        else return "Confirm";
      }
      if (this.submitted) {
        if (this.error) return "Retry Failures";
        else return "Back To Start";
      }
    },
    deviceIds() {
      const existingIds = this.inputs.ids ? this.inputs.ids.split(/[,\s\n]/).filter((s) => s != null && s != "") : [];
      if (this.selectedDevices.length === 0) {
        return existingIds;
      }

      const ids = [...new Set([...existingIds, ...this.selectedDevices])];

      return ids;
    },
    lastStepText() {
      if (!this.submitted) return "Please confirm changes:";
      if (this.error) return `<span class="red--text">Error!</span> ${this.error}`;
      return `<span class="success--text">Success!</span> Subscription plan has been updated!`;
    },
    settingOptions() {
      const options = [{ value: "expiry", label: "Expiry Date" }, { value: "custom", label: "Custom Date" }];
      return options;
    },
    validators: () => validators,
  },
  methods: {
    nextStep() {
      if (this.menu === "insert-manager-name") {
        switch (this.menu) {
          case "insert-manager-name":
            this.goSearch();
            break;
        }
      }
    },
    formatDecimal: formatDecimal,
    formatToDecimal(value, inputKey) {
      if (isDecimalExceedLimit(value, 2)) {
        const decimalNumber = stringToDecimal(value);
        this.$nextTick(() => {
          if (this.inputs[inputKey] !== decimalNumber) this.inputs[inputKey] = decimalNumber;
        });
      }
    },
    formatValue(num) {
      if (!num) return 0;
      var parts = num.toString().split(".");
      return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
    },
    setPaygStartTimeUnix() {
      if (this.selectedSetting === "custom") {
        if (!this.inputs.time.date || !this.inputs.time.clock) return 0;
        const combinedDateTime = `${this.inputs.time.date} ${this.inputs.time.clock}`;
        return this.$moment(combinedDateTime, "YYYY-MM-DD HH:mm:ss").unix();
      }
      return 0;
    },
    goSearch() {
      this.loading = true;
      this.error = null;
      this.lookup = true;
      this.search = this.search.trim();
      this.$store.dispatch("resetState");
      this.$store.dispatch("Admin/resetState");

      Api.adminOwner({ ownerUsername: this.search })
        .then((owners) => {
          console.log(owners);
          if (owners.length > 0) {
            const owner = owners[0];
            this.$store.commit("Admin/setSearchedUsers", this.search);
            this.$store.commit("Admin/setState", { managerview: owner });
            this.$store.dispatch("countData");
            this.loading = false;

            this.$nextTick(() => {
              this.$store.dispatch("Admin/getPreferencesForView");
              this.menu = "device-table";
            });
          } else this.error = "Manager Does Not Exist";
        })
        .catch((error) => {
          this.error = "Manager Does Not Exist";
          this.loading = false;
        });
    },
    getPlugs() {
      this.devices = [];
      this.loading = true;
      let requests = [];

      const processDevice = (plugIdentifier, deviceResponse) => {
        const device = deviceResponse[0];

        if (!device) {
          return Promise.resolve();
        }

        device.expiry_date = this.$moment.unix(device.expiry_timestamp).format("YYYY-MM-DD HH:mm:ss");

        return Api.adminPlugGetDataplanConfig({ plugIdentifier })
          .then((dataplan) => {
            if (!dataplan) {
              return;
            }

            const payAsYouGo = dataplan.pay_as_you_go ? JSON.parse(dataplan.pay_as_you_go) : null;

            device.monthlyCad = dataplan.cost_cad;
            device.monthlyUsd = dataplan.cost_usd;
            const payAsYouGoCad = (payAsYouGo?.priceList || []).find((p) => p.currency === "CAD") || {};
            const payAsYouGoUsd = (payAsYouGo?.priceList || []).find((p) => p.currency === "USD") || {};
            const startOfBilling = payAsYouGo ? payAsYouGo.startOfBilling || null : null;
            device.paygCad = payAsYouGoCad.cost || 0;
            device.paygUsd = payAsYouGoUsd.cost || 0;

            const timespan = dataplan.timespan > 0 ? dataplan.timespan : 0;
            device.existing_initial_subscription_time_days = timespan / (24 * 3600); // convert timespan in unit seconds to days

            device.paygStart = device.owner_id ? (startOfBilling ? this.$moment.unix(startOfBilling).unix() : null) : null;
            device.new_monthlyUsd = parseFloat(this.inputs.monthlyUsd);
            device.new_monthlyCad = parseFloat(this.inputs.monthlyCad);
            device.new_paygCad = parseFloat(this.inputs.paygCad);
            device.new_paygUsd = parseFloat(this.inputs.paygUsd);

            let newPaygTime = this.selectedSetting === "expiry" ? this.$moment.unix(device.expiry_timestamp).unix() : this.setPaygStartTimeUnix();
            device.newPaygStart = device.owner_id ? newPaygTime : null;

            device.new_initial_subscription_time_days = Math.floor(
              parseFloat(this.inputs.time.years) * 365 + parseFloat(this.inputs.time.months) * 30 + parseFloat(this.inputs.time.days)
            );
            device.new_initial_subscription_time = device.new_initial_subscription_time_days * 24 * 60 * 60; // convert timespan to seconds for API payload

            this.devices.push(device);
          })
          .catch((error) => {
            console.error(`Error fetching data plan for device with identifier ${plugIdentifier}:`, error);
          });
      };

      this.deviceIds.forEach((plugIdentifier) => {
        requests.push(
          Api.plug("admin", { plugIdentifier })
            .then((deviceResponse) => processDevice(plugIdentifier, deviceResponse))
            .catch((err) => console.log(err))
        );
      });

      Promise.all(requests)
        .then(() => {
          this.$nextTick(() => this.goNext());
        })
        .catch((err) => {
          console.error("Error fetching devices and data plans:", err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async submit(only_failed = false) {
      this.loading = true;

      for (let i = 0; i < this.devices.length; i++) {
        const device = this.devices[i];
        const plugIdentifier = device.identifier;
        if (only_failed && this.devices[i].isUpdateSuccess) {
          continue;
        }

        const payload = {
          plugIdentifier,
          monthlyCad: this.devices[i].new_monthlyCad,
          monthlyUsd: this.devices[i].new_monthlyUsd,
          paygCad: this.devices[i].new_paygCad,
          paygUsd: this.devices[i].new_paygUsd,
          timespan: this.devices[i].new_initial_subscription_time,
          paygStart: this.devices[i].newPaygStart,
        };

        await Api.adminPlugSetDataplanConfig(payload)
          .then(() => {
            this.devices[i].isUpdateSuccess = true;
          })
          .catch((error) => {
            this.devices[i].isUpdateSuccess = false;
            this.error = "Failed to set device(s) subscription plan";
            console.error("Error updating devices:", error);
          });
      }

      this.loading = false;
      this.submitted = true;

      this.$store.dispatch("snackbar", { message: "Successful Update Subscription Plan" });
    },
    goBack() {
      switch (this.menu) {
        case "insert-device-ids":
          this.id = false;
          this.menu = null;
          break;
        case "insert-manager-name":
          this.lookup = false;
          this.menu = null;
          break;
        case "set-initial-duration":
          if (this.id) {
            this.reset();
            this.menu = "insert-device-ids";
          } else if (this.lookup) {
            this.menu = "device-table";
          }
          break;
        case "configure-subscription-plan":
          this.menu = "set-initial-duration";
          break;
        case "table-summary":
          this.menu = "configure-subscription-plan";
          break;
        case "device-table":
          this.reset();
          this.menu = "insert-manager-name";
          break;
      }
    },
    goNext(btnId) {
      if (this.menu === null) {
        switch (btnId) {
          case "device-ids":
            this.menu = "insert-device-ids";
            this.id = true;
            break;
          case "lookup-manager":
            this.menu = "insert-manager-name";
            this.lookup = true;
            break;
        }
      } else {
        switch (this.menu) {
          case "insert-manager-name":
            this.goSearch();
            break;
          case "device-table":
            this.inputs.ids = this.deviceIds.join(",");
            this.menu = "set-initial-duration";
            break;
          case "set-initial-duration":
            this.menu = "configure-subscription-plan";
            break;
          case "configure-subscription-plan":
            this.getPlugs();
            this.menu = "table-summary";
            break;
          case "table-summary":
            if (this.submitted) {
              this.$router.push("/admin/configure-device-subscriptions-plan");
              this.menu = null;
              this.reset();
            }
            break;

          case "insert-device-ids":
            this.inputs.ids = this.deviceIds.join(",");
            this.menu = "set-initial-duration";
            break;
          case "set-initial-duration":
            this.menu = "configure-subscription-plan";
            break;
          case "configure-subscription-plan":
            this.getPlugs();
            this.menu = "table-summary";
            break;
          case "table-summary":
            if (this.submitted) {
              this.$router.push("/admin/configure-device-subscriptions-plan");
              this.menu = null;
              this.reset();
            }
            break;
        }
      }
    },
    reset() {
      this.search = null;
      this.menu = null;
      this.selectedDevices = [];
      this.devices = [];
      this.error = null;
      this.loading = false;
      this.submitted = false;
      this.selectedSetting = "expiry";
      this.showAdvanced = false;
      this.inputs = {
        ids: null,
        monthlyCad: null,
        monthlyUsd: null,
        paygCad: null,
        paygUsd: null,
        time: {
          years: null,
          months: null,
          days: null,
          date: null,
          clock: "23:59",
        },
      };
    },
  },
  watch: {
    selectedSetting(newVal) {
      if (newVal) {
        this.inputs.time = {
          years: this.inputs.time.years,
          months: this.inputs.time.months,
          days: this.inputs.time.days,
          date: this.inputs.time.date,
          clock: "23:59",
        };
      }
    },
  },
  mounted() {
    if (this.$route.query.device) {
      this.inputs.ids = this.$route.query.device;
      this.$nextTick(() => {
        this.goNext();
      });
    }
  },
};
</script>

<style scoped lang="scss">
.v-datatable {
  th {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    &:first-child {
      border-left: 0px;
    }
  }
  tr {
    border: 1px solid rgba(0, 0, 0, 0.12);
    td {
      border-left: 1px solid rgba(0, 0, 0, 0.12);
      &:first-child {
        border-left: 0px;
      }
    }
  }
  th.multiheader {
    tr {
      border: 0px;
      height: 27px;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      display: flex;
      align-items: center;
      justify-content: center;
      &:first-child {
        border-top: 0px;
        width: 100%;
      }
      td {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        padding: 0px 40px;
      }
    }
  }
}
.theme--light.v-table thead th {
  color: rgba(0, 0, 0);
  font-weight: bolder;
}
.table-container {
  overflow-x: scroll;
}
</style>
