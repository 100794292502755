<template>
  <v-layout xs12 row wrap class="pa-4">
    <v-flex xs12 d-flex>
      <v-layout row>
        <h1
          id="title"
          class="d-inline-block white-space__nowrap"
        >
          {{ title }}
        </h1>
        <HeartbeatInputModal
          class="ml-4"
          @done="addGraph"
        />
      </v-layout>
      <v-spacer />
      <TimezoneInputModal />
    </v-flex>
    <v-flex 
      v-for="(graph, i) in graphs"
      :key="`${i}_${key}`"
      :xs6="!graph.maximized"
      :xs12="graph.maximized"
    >
      <HeartbeatGraph
        :time-range="graph.timeRange"
        :data="graph.data"
        :x-axis="graph.axes.x"
        :y-axis="graph.axes.y"
        :actions="getGraphActions(i)"
        @moveLeft="move(i, i-1)"
        @moveRight="move(i, i+1)"
        @remove="graphs.splice(i, 1)"
        @maximize="maximize(i, true)"
        @minimize="maximize(i, false)"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import { cloneDeep } from 'lodash-es';
import { v4 as uuidv } from "uuid"
import TimezoneInputModal from '@/components/modals/TimezoneInputModal.vue';
import HeartbeatInputModal from '@/components/modals/HeartbeatInputModal.vue';
import HeartbeatGraph from '@/components/graphs/HeartbeatGraph.vue';
export default {
  name: "HeartbeatAnalytics",
  components: {
    TimezoneInputModal,
    HeartbeatInputModal,
    HeartbeatGraph
  },
  data() {
    return {
      graphs: [],
      key: uuidv()
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
    }),
    ...mapGetters("Admin", {
      deviceview: "formattedDeviceView",
      viewAsOwner: "viewAsOwner",
    }),
    title() {
      if (this.role == "admin") {
        if (!!this.deviceview) return `${this.deviceview.identifier} > Heartbeat Analytics`;
      }
      return "Heartbeat Analytics";
    },
  },
  methods: {
    addGraph(graph) {
      this.graphs.push({ ...graph, maximized: false })
    },
    getGraphActions(i) {
      const graph = this.graphs[i]

      const actions = [graph.maximized ? "minimize" : "maximize"]

      if (this.graphs.length > 1) {
        if (i > 0) actions.push("moveLeft")
        if (i < this.graphs.length - 1) actions.push("moveRight")
      }
      return actions
    },
    move(currentIndex, targetIndex) {
      const graphs = cloneDeep(this.graphs)
      const current = graphs[currentIndex]
      const target = graphs[targetIndex]
      this.graphs[targetIndex] = current
      this.graphs[currentIndex] = target
      this.key = uuidv()
    },
    maximize(index, value) {
      this.graphs[index].maximized = value
      this.key = uuidv()
    }
  },
  mounted() {
    if (this.role === "admin" && !this.viewAsOwner) return this.$router.push(`/admin/deviceview`);
    this.key = uuidv()
  },
}
</script>