<template>
  <div>
    <v-card class="ma-2">
      <v-card-title>
        <h3 id="change-user-password-tile">Change User Password</h3>
      </v-card-title>
      <v-alert :value="error" type="error" dismissible>
        {{ error }}
      </v-alert>
      <v-alert :value="success" type="success" dismissible>
        New password saved
      </v-alert>
      <v-card-text class="text-xs-center">
        <v-form
          ref="changeUserPasswordForm"
          @submit="submit()"
        >
          <h3 class="text-xs-justify mb-3 mt-0"> 
            <span class="red--text">Warning:</span> When changing the password of a user account, the previous password cannot be restored.
            Please make sure this change is intentional
          </h3>
          <v-text-field
            id="change-user-password-field"
            :maxlength="50"
            outline
            label="New Password"
            v-model="newPassword"
            :rules="[validators.required, validators.password]"
            :error="!!error"
            :disabled="loading"
            required
            prepend-inner-icon="vpn_key"
            :append-icon="show_new_password ? 'visibility' : 'visibility_off'"
            :type="show_new_password ? 'text' : 'password'"
            @keyup.enter="submit()"
            @click:append="show_new_password = !show_new_password"
          />
          <v-text-field
            id="confirm-user-password-field"
            :maxlength="50"
            outline
            label="Confirm New Password"
            v-model="confirmPassword"
            :rules="[validators.required, validators.password, validators.password_confirm]"
            :error="!!error"
            :disabled="loading"
            required
            prepend-inner-icon="vpn_key"
            :append-icon="show_confirm_password ? 'visibility' : 'visibility_off'"
            :type="show_confirm_password  ? 'text' : 'password'"
            @keyup.enter="submit()"
            @click:append="show_confirm_password  = !show_confirm_password "
          />
          <v-layout xs12>
            <v-spacer />
            <v-btn
              id="change-password-save-button"
              class="plugzio-button mt-2"
              @click="submit()"
              outline
              round
              :loading="loading"
              :disabled="loading"
            >
              Submit
            </v-btn>
          </v-layout>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Api from "@library/apis/Api";
import validators from '@/library/helpers/validators';
import { mapState } from "vuex"

export default {
  name: "changeUserPasswordForm",
  data() {
    return {
      newPassword: null,
      confirmPassword: null, 
      show_new_password: false,
      show_confirm_password: false,
      success: false, 
      error: false, 
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
      loading: (state) => state.loading,
      activeAdminView: (state) => state.Admin.activeView,
      userview: (state) => state.Admin.userview,
    }),
    validators() {
      return {
        ...validators, 
        password_confirm: (value) => { 
          if (!value) return ""
          return this.newPassword === this.confirmPassword || "Passwords does not match"
        }
      }
    },
  },
  methods: {
    submit() {
      if (this.$refs.changeUserPasswordForm.validate()) {
        this.success = false; 
        this.error = false;
        this.$store.dispatch("loading", true);

        Api.adminUserSetPassword({ username: this.userview.username, password: this.confirmPassword })
          .then(() => { 
            this.success = true; 
            this.$refs.changeUserPasswordForm.reset();
          })
          .catch((error) => {
            this.error = error.response.data;
          })
          .finally(() => {
            this.$store.dispatch("loading", false);
          });
      }
    }
  },
  mounted() {
    if (this.role == "admin" && !this.userview) return this.$router.push(`/admin/userview`);
  } 
};
</script>
