<template>
  <v-layout xs12 row justify-center align-center class="h-full">
    <v-flex md6 sm12 xs12>
      <SearchCard
        :error="error"
        :loading="loading"
        v-model="search"
        @search="goSearch"
        @input="(v) => (search = v)"
        title="Lookup Managers"
        inputLabel="Enter Manager E-mail"
        searched-list-title="Searched Managers:"
      />
    </v-flex>
  </v-layout>
</template>
<script>
import Api from "./../../library/apis/Api";
import { mapState } from "vuex";
import SearchCard from "./components/SearchCard";
export default {
  components: {
    SearchCard,
  },
  data() {
    return {
      loading: false,
      error: null,
      search: null,
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
      managerview: (state) => state.Admin.managerview,
    }),
  },
  methods: {
    goSearch() {
      this.loading = true;
      this.error = null;
      this.search = this.search.trim();
      this.$store.dispatch("resetState");
      this.$store.dispatch("Admin/resetState");

      Api.adminOwner({ ownerUsername: this.search })
        .then((owners) => {
          if (owners.length > 0) {
            const owner = owners[0];
            this.$store.commit("Admin/setSearchedUsers", this.search);
            this.$store.commit("Admin/setState", { managerview: owner });
            this.$store.dispatch("countData");
            this.$router.push("/admin/managerview-dashboard");
            this.loading = false;
            this.$nextTick(() => {
              this.$store.dispatch("Admin/getPreferencesForView");
            });
          } else this.error = "Manager Does Not Exist";
        })
        .catch((error) => {
          this.error = "Manager Does Not Exist";
          this.loading = false;
        });
    },
  },
  mounted() {
    if (this.$route.query.search) {
      this.search = this.$route.query.search;
      this.$nextTick(() => {
        this.goSearch();
      });
    }
  },
};
</script>
<style lang="scss">
.h-full {
  height: 100%;
}
</style>
