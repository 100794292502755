<template>
  <v-layout xs12 row wrap class="pa-4">
    <v-flex xs12 d-flex>
      <h1 id="title" class="d-inline-block white-space__nowrap">{{ title }}</h1>
      <v-spacer />
      <TimezoneInputModal />
    </v-flex>
    <v-flex xs12>
      <DeviceInformationCard
        :device="deviceview"
      />
    </v-flex>
    <v-flex xs12 mt-4>
      <DeviceLogsCard
        :device="deviceview"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import DeviceInformationCard from '@/components/cards/DeviceInformationCard.vue';
import DeviceLogsCard from '../components/DeviceLogsCard.vue';
import TimezoneInputModal from '@/components/modals/TimezoneInputModal.vue';
export default {
  name: "InfoAndLogs",
  components: {
    DeviceInformationCard,
    DeviceLogsCard,
    TimezoneInputModal
  },
  data() {
    return {
      manufacturedPlug: {}
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
    }),
    ...mapGetters("Admin", {
      deviceview: "formattedDeviceView",
      viewAsOwner: "viewAsOwner",
    }),
    title() {
      if (this.role == "admin") {
        if (!!this.deviceview) return `${this.deviceview.identifier} > Info & Logs`;
      }
      return "Info & Logs";
    },
  },
  mounted() {
    if (this.role === "admin" && !this.viewAsOwner) return this.$router.push(`/admin/deviceview`);
  },
}
</script>