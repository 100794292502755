<template>
  <v-layout row wrap>
    <v-flex xs6>
      <h1 class="d-inline-block">
        APIs
      </h1>
    </v-flex>
    <v-flex xs6 class="text-xs-right page-toolbar">
      <date-range class="date-range" />
    </v-flex>
    <v-flex lg4 md6 sm12 xs12 v-for="(item, index) in items" :key="index">
      <v-card class="access-tile ma-2">
        <v-card-title @click.stop="item.show = !item.show">
          <strong>{{ item.name }}</strong>
          <v-spacer></v-spacer>
          <v-btn flat small icon class="ma-0" @click.stop="item.show = !item.show">
            <v-icon v-if="item.show">keyboard_arrow_up</v-icon>
            <v-icon v-else>keyboard_arrow_down</v-icon>
          </v-btn>
        </v-card-title>
        <div v-if="item.show">
          <v-card-text class="pb-0">
            <div v-for="(field, index) in item.fields" :key="index">
              <v-text-field
                :type="fieldType(field.name)"
                v-if="field.name !== 'till' && field.name !== 'since'"
                outline
                v-model="field.value"
                :label="humanize(unCamelCase(field.name))"
                @keydown="integerFilter"
                autocomplete="false"
                required
              ></v-text-field>
              <v-menu
                v-if="field.name === 'till' || field.name === 'since'"
                v-model="field.show"
                :close-on-content-click="false"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field v-model="field.value" :label="humanize(unCamelCase(field.name))" outline readonly v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="field.value" @input="field.show = false"></v-date-picker>
              </v-menu>
            </div>
          </v-card-text>
          <v-card-actions class="pa-3">
            <v-spacer></v-spacer>
            <v-btn round outline small @click.stop="lookup(item)" :loading="busy">
              Lookup
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-flex>
    <v-flex xs12 v-if="lookedUp">
      <h1 class="d-block mt-3" v-if="results.length">
        Results
      </h1>
      <v-card class="access-tile ma-2 mt-4" v-for="(result, index) in results" :key="index">
        <v-card-title>
          <h3>{{ result.time }} - {{ result.item.name }}</h3>
          <v-spacer></v-spacer>
          <v-btn flat small icon class="ma-0 mr-2" @click.stop="lookup(result.item)" :disabled="busy">
            <v-icon>refresh</v-icon>
          </v-btn>
          <v-btn flat small icon class="ma-0 mr-2" @click.stop="result.show = !result.show" :disabled="busy">
            <v-icon v-if="result.show">keyboard_arrow_up</v-icon>
            <v-icon v-else>keyboard_arrow_down</v-icon>
          </v-btn>
          <v-btn flat small icon class="ma-0" @click.stop="remove(index)" :disabled="busy">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="font-size: .9em">
          {{ result.payload }}
        </v-card-text>
        <vue-good-table 
          v-show="result.show" 
          :columns="result.headers" 
          :rows="result.items" 
        >
          <div slot="emptystate" v-if="!busy">
            &nbsp; No result
          </div>
          <div slot="emptystate" v-else>
            &nbsp; Fetching results...
          </div>

          <template slot="table-row" slot-scope="props">
            <span>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </v-card>
    </v-flex>
    <v-dialog v-model="errorModal" max-width="360">
      <v-card v-if="error">
        <v-card-title
          ><h3>{{ "Error Code: " + error.status }}</h3></v-card-title
        >
        <v-card-text class="text-xs-center">
          <h2 class="mb-2">{{ this.error.data }}</h2>
          {{ this.error.config.data }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray" flat="flat" @click="errorModal = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import mixin from "@/mixins";
import Api from "@library/apis/Api";
import { isArray } from 'lodash';
import DateRange from "@/components/modals/DateRange";

export default {
  mixins: [mixin],
  components: {
    DateRange,
  },
  data: () => ({
    busy: false,
    error: false,
    result: [],
    lookedUp: null,
    pickerTill: false,
    pickerSince: false,
    errorModal: false,
    results: [],
    items: [
      {
        name: "Manufactured Outlet Lookup",
        show: false,
        value: 1,
        url: "manufactured-plugs",
        fields: [],
        headers: [["ID", "Row ID"], ["External Identifier", "External Identifier (QR Code)"]],
      },
      {
        name: "Session Lookup - By Session ID",
        show: false,
        value: 2,
        url: "session",
        fields: [
          {
            name: "sessionId",
            value: null,
          },
        ],
        headers: [["ID", "Session ID"]],
      },
      {
        name: "Session Lookup - By User E-mail",
        show: false,
        value: 3,
        url: "user/sessions",
        fields: [
          {
            name: "username",
            value: null,
          },
          {
            name: "since",
            show: false,
            value: null,
          },
          {
            name: "till",
            show: false,
            value: null,
          },
        ],
        headers: [],
      },
      {
        name: "Session Lookup - By Manager E-mail",
        show: false,
        value: 3,
        url: "owner/sessions",
        fields: [
          {
            name: "ownerUsername",
            value: null,
          },
          {
            name: "since",
            show: false,
            value: null,
          },
          {
            name: "till",
            show: false,
            value: null,
          },
        ],
        headers: [],
      },
      {
        name: "Session Lookup - By QR Code or ID",
        show: false,
        value: 4,
        url: "plug/sessions",
        fields: [
          {
            name: "plugId",
            value: null,
          },
          {
            name: "plugIdentifier",
            value: null,
          },
          {
            name: "since",
            show: false,
            value: null,
          },
          {
            name: "till",
            show: false,
            value: null,
          },
        ],
        headers: [["ID", "Session ID"]],
      },
      {
        name: "Session Log Lookup",
        show: false,
        value: 5,
        url: "session/log",
        fields: [
          {
            name: "sessionId",
            value: null,
          },
          {
            name: "since",
            show: false,
            value: null,
          },
          {
            name: "till",
            show: false,
            value: null,
          },
        ],
        headers: [["Consumption", "Consumption (kWh)"], ["Logitem Timestamp", "Log Item Timestamp (Epoch)"], ["Voltage", "Voltage (V)"], ["Current", "Current (A)"]],
      },
      {
        name: "Outlet Lookup - By QR Code or ID",
        show: false,
        value: 6,
        url: "plug",
        fields: [
          {
            name: "plugId",
            value: null,
          },
          {
            name: "plugIdentifier",
            value: null,
          },
        ],
        headers: [["ID", "Outlet ID"]],
      },
      {
        name: "Outlet Lookup - By Manager E-mail",
        show: false,
        value: 7,
        url: "owner/plugs",
        fields: [
          {
            name: "ownerUsername",
            value: null,
          },
        ],
        headers: [["ID", "Outlet ID"]],
      },
      {
        name: "Outlet Access Lookup",
        show: false,
        value: 8,
        url: "plug/users",
        fields: [
          {
            name: "plugId",
            value: null,
          },
          {
            name: "plugIdentifier",
            value: null,
          },
        ],
        headers: [["Plug Payment Access Plan ID", "Payment Plan ID"]],
      },
      {
        name: "Payment Plan Lookup - By Plan ID",
        show: false,
        value: 9,
        url: "plug-payment-access-plan",
        fields: [
          {
            name: "planId",
            value: null,
          },
        ],
        headers: [["ID", "Payment Plan ID"]],
      },
      {
        name: "Payment Plan Lookup - By Manager E-mail",
        show: false,
        value: 10,
        url: "owner/plug-payment-access-plans",
        fields: [
          {
            name: "ownerUsername",
            value: null,
          },
        ],
        headers: [["ID", "Payment Plan ID"]],
      },
      {
        name: "Wallet Lookup - By Wallet ID",
        show: false,
        value: 11,
        url: "wallet",
        fields: [
          {
            name: "walletId",
            value: null,
          },
        ],
        headers: [["ID", "Wallet ID"]],
      },
      {
        name: "Wallet Lookup - By Manager ID or E-mail",
        show: false,
        value: 12,
        url: "owner/wallets",
        fields: [
          {
            name: "ownerId",
            value: null,
          },
          {
            name: "ownerUsername",
            value: null,
          },
        ],
        headers: [["ID", "Wallet ID"]],
      },
      {
        name: "Wallet Lookup - By User ID or E-mail",
        show: false,
        value: 13,
        url: "user/wallets",
        fields: [
          {
            name: "userId",
            value: null,
          },
          {
            name: "username",
            value: null,
          },
        ],
        headers: [["ID", "Wallet ID"]],
      },
      {
        name: "Transaction Lookup",
        show: false,
        value: 14,
        url: "wallet/transactions",
        fields: [
          {
            name: "walletId",
            value: null,
          },
          {
            name: "since",
            show: false,
            value: null,
          },
          {
            name: "till",
            show: false,
            value: null,
          },
        ],
        headers: [["ID", "Transaction ID"], ["Record Timestamp", "Record Timestamp (Epoch)"]],
      },
      {
        name: "Manager Lookup",
        show: false,
        value: 15,
        url: "owner",
        fields: [
          {
            name: "ownerId",
            value: null,
          },
          {
            name: "ownerUsername",
            value: null,
          },
        ],
        headers: [["ID", "Manager ID"]],
      },
      {
        name: "User Lookup",
        show: false,
        value: 16,
        url: "user",
        fields: [
          {
            name: "userId",
            value: null,
          },
          {
            name: "username",
            value: null,
          },
        ],
        headers: [["ID", "User ID"]],
      },
      {
        name: "Manager Get Preferences",
        show: false,
        value: 17,
        url: "owner/get-prefs",
        fields: [
          {
            name: "ownerUsername",
            value: null,
          },
        ],
        headers: [],
      },
      {
        name: "User Get Preferences",
        show: false,
        value: 18,
        url: "user/get-prefs",
        fields: [
          {
            name: "username",
            value: null,
          },
        ],
        headers: [],
      },
    ],
  }),
  computed: {
    tableHeaders() {
      let data = [];
      if (this.tableItems && this.tableItems[0]) {
        Object.keys(this.tableItems[0]).forEach((key, index) => {
          data.push({
            label: this.humanize(key),
            field: key,
            sortable: false,
          });
        });
      }
      return data;
    },
    tableItems() {
      let result = this.result;
      if (!isArray(result)) {
        return [{ response: result || {} }]
      } 
      let data = result.map((o) => {
        for (let k in o) {
          if (o.hasOwnProperty(k)) {
            if (k === "starttime" || k === "endtime" || k === "last_update_timestamp") {
              o[k] = this.formatTimestamp(o[k]);
            }
            if (k === "total_consumption") {
              o[k] = (o[k] / 1000).toFixed(3);
            }
          }
        }
        return o;
      });
      return data;
    },
    dateFormattedSince() {
      return this.$moment(this.storedTime.since * 1000).format("YYYY-MM-DD");
    },
    dateFormattedTill() {
      return this.$moment(this.storedTime.till * 1000).format("YYYY-MM-DD");
    },
    storedTime() {
      return this.$store.getters.storedTime;
    },
  },
  methods: {
    lookup(item) {
      this.$nextTick(() => {
        this.lookedUp = item;

        let url = `${API_URL}/admin/${item.url}`;
        let params = item.fields.reduce((obj, item) => {
          obj[item.name] = item.value ? item.value : null;
          return obj;
        }, {});

        if (params["till"]) params["till"] = this.$moment(params["till"]).unix();
        if (params["since"]) params["since"] = this.$moment(params["since"]).unix();

        this.busy = true;
        Api.runApi("POST", url, params)
          .then((data) => {
            this.result = data || [];
            this.$nextTick(() => {
              let resultData = {
                item,
                url,
                data,
                show: true,
                payload: params,
                time: this.$moment().format("DD-MMM-YYYY HH:mm:ss"),
                headers: this.tableHeaders,
                items: this.tableItems,
              };
              this.processResults(JSON.parse(JSON.stringify(resultData)));
            });
          })
          .catch((error) => {
            console.log(error);
            this.error = error.response;
            this.errorModal = true;
            this.busy = false;
          });
      });
    },
    processResults(data) {
      setTimeout(() => {
        this.results.unshift(data);
        this.busy = false;
      }, 1000);
    },
    setUpDate() {
      this.items.forEach((o) => {
        o.fields.map((f) => {
          if (f.name === "till") f.value = this.dateFormattedTill;
          if (f.name === "since") f.value = this.dateFormattedSince;
        });
      });
    },
    formatTimestamp(time) {
      return time ? this.$moment.unix(time).format("DD-MMM-YYYY HH:mm:ss") : "";
    },
    unCamelCase(str) {
      str = str.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, "$1 $2");
      str = str.toLowerCase();
      return str;
    },
    humanize(field) {
      let str = field.replace(" ", "_");
      let frags = str.split("_");
      for (let i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        if (frags[i] === "Id") frags[i] = "ID";
      }

      let header = this.rename(frags.join(" "));

      if (this.lookedUp && this.lookedUp.headers.length) {
        for (let i = 0; i < this.lookedUp.headers.length; i++) {
          if (header === this.lookedUp.headers[i][0]) header = this.lookedUp.headers[i][1];
        }
      }

      return header;
    },
    rename(str) {
      switch (str) {
        case "Plug ID":
          return "Outlet ID";
        case "Owner ID":
          return "Manager ID";
        case "Plan ID":
          return "Payment Plan ID";
        case "Plug Identifier":
          return "QR Code";
        case "Identifier":
          return "QR Code";
        case "Plugmode":
          return "Power Settings";
        case "Owner Username":
          return "Manager E-mail";
        case "Total Consumption":
          return "Total Consumption (kWh)";
        case "Username":
          return "User E-mail";
        case "Starttime":
          return "Start Time (local)";
        case "Endtime":
          return "End Time (local)";
        case "Start Timestamp":
          return "Start Timestamp (Epoch)";
        case "Expiry Timestamp":
          return "Expiry Timestamp (Epoch)";
        case "Plug Payment Access Plan":
          return "Payment Plan";
        case "Ispublic":
          return "Is Public";
        default:
          return str;
      }
    },
    fieldType(field) {
      let numFields = ["userId", "ownerId", "sessionId", "plugId", "planId", "walletId"];
      return numFields.includes(field) ? "number" : "text";
    },
    remove(index) {
      this.results.splice(index, 1);
    },
    headerType(field) {
      if (isNaN(field)) {
        return "number";
      }
      return "text";
    },
    integerFilter(e) {
      const key = e.key;
      if (e.target.type === "number") {
        if (key === ".") return e.preventDefault();
      }
    },
  },
  watch: {
    storedTime() {
      this.setUpDate();
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.getElementById("app").removeAttribute("style");
    });
  },
};
</script>

<style lang="sass">
.v-label
  // text-transform: capitalize

.vgt-table th
  font-weight: 500 !important
  color: #676767
  font-size: 12px
  background: #f1f1f1 !important

.vgt-table.bordered td, .vgt-table.bordered th
  border: 1px solid #e6e6e6

table.vgt-table
  font-size: 14px
</style>

<style lang="scss">
.plug-list {
  padding: 10px;
}

.plug-tile-details {
  font-size: 0.75em;
  color: gray;
  color: #949fa8;
  font-weight: 400;
  padding-left: 16px;
  @media only screen and (max-width: 560px) {
    padding-left: 4px;
  }
}

.plug-tile-details {
  .black--text {
    color: #444;
  }
}

.activate-button {
  margin-top: -5px;
  min-width: 100px;
}

.session-button {
  min-width: 100px;
}

.session-tile {
  background: #f8f8f8;
  border-radius: 4px;
  margin-bottom: 15px;
  border-left: 4px solid lightgray;
  overflow: hidden;
}

.session-tile {
  .v-list__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    min-height: 88px;
    @media only screen and (max-width: 560px) {
      padding: 0px 8px;
    }
  }
}

.recent-plugs {
  padding-top: 14px;
  padding-bottom: 14px;
}

.session-active {
  border-color: #4caf50;
  background: rgba(221, 236, 221, 0.4);
}

.session-active {
  .plug-avatar {
    color: #ffffff !important;
  }
}

.session-tile:last-child {
  margin-bottom: 0;
}

.session-list {
  padding-top: 8px;
  padding-bottom: 8px;
}

.plug-avatar {
  width: 78px;
  height: 78px;
  background: #f8f8f8;
  margin-top: 17px;
  border-radius: 4px;
  border: 1px solid #dadada;
  @media only screen and (max-width: 560px) {
    height: 64px;
    width: 64px;
  }
}
.v-list__tile__avatar {
  min-width: 78px;
  min-height: 78px;
  @media only screen and (max-width: 560px) {
    min-height: 64px;
    min-width: 64px;
  }
}

.v-avatar {
  height: 78px !important;
  width: 78px !important;
  // margin-right: 18px;
  @media only screen and (max-width: 560px) {
    height: 64px !important;
    width: 64px !important;
  }
}

.v-list__tile__sub-title {
  font-size: 12px;
}
.v-card {
  cursor: auto;
}

.button-off {
  border-color: gray !important;
  color: gray !important;
}

.help-sample-image {
  max-width: 320px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
